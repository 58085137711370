<template>
  <el-radio-group 
    class="radio-group" 
    :value="value" 
    @input="updateValue"
  >
    <el-radio
      v-for="option in options"
      :key="option.value"
      :label="option.value"
    >
      {{ option.label }}
    </el-radio>
  </el-radio-group>
</template>

<script>
export default {
  name: 'Radio',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    options: {
      type: Array,
      required: true,
      validator: (options) => {
        return options.every(option => 'label' in option && 'value' in option);
      }
    }
  },
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
.radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0;
  
  .el-radio__inner {
    &:hover {
      border-color: $primary;
    }
    &::after {
      width: 6px;
      height: 6px;
    }
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: $primary;
    background: $primary;
  }

  .el-radio__input.is-checked + .el-radio__label, 
  .el-radio__label {
    color: $basicBlack;
    font-weight: 400;
    font-size: 16px;
    padding-left: 8px;
  }

  .el-radio {
    margin-right: 15px;
  }
}
</style>