<template>
  <Selector
    class="analytics-account-selector"
    required
    title="Выберите аккаунт"
    isMulti
    limitOptionsHeight
    :options="serviceOptions"
    :value="checkedValues"
    :select-fitrst-item="false"
    @onChange="handleSelectorChange"
  />
</template>
  
<script>
import Selector from "@/components/sort/Selector";
import { ServiceType, ServiceName } from "@/utils/services";

export const RadioOptionsKey = {
  IMPRESSIONS: 'IMPRESSIONS',
  CLICKS: 'CLICKS',
  CTR: 'CTR',
  AVERAGE_CPC: 'AVERAGE_CPC',
  EXPENSES: 'EXPENSES'
};

export const RadioOptionsText = {
  [RadioOptionsKey.IMPRESSIONS]: 'Показы',
  [RadioOptionsKey.CLICKS]: 'Клики',
  [RadioOptionsKey.CTR]: 'CTR',
  [RadioOptionsKey.AVERAGE_CPC]: 'Средняя цена клика',
  [RadioOptionsKey.EXPENSES]: 'Расходы'
};

export default {
  name: 'AnalyticsControls',
  components: {
    Selector,
  },
  data() {
    return {
      ServiceType, 
      ServiceName,
      checkedValues: ['AVITO', 'GOOGLE'],
      selectedOption: RadioOptionsKey.IMPRESSIONS,
      radioOptions: Object.keys(RadioOptionsKey).map(key => ({
        label: RadioOptionsText[RadioOptionsKey[key]],
        value: RadioOptionsKey[key]
      }))
    };
  },
  computed: {
    serviceOptions() {
      return Object.keys(ServiceType).map((serviceType) => ({ id: serviceType, name: ServiceName[serviceType] }))
    }
  },
  methods: {
    changeDate() {

    },
    handleSelectorChange(service) {
      console.log('Services', service)
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
.analytics-controls {
  display: grid;
  grid-template-areas: 'service account company' 'radiogroup radiogroup datepicker';
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  align-items: center;

  &__service-selector {
    grid-area: service;
  }
  &__account-selector {
    grid-area: account;
  }
  &__company-selector {
    grid-area: company;
  }
  &__radio-group {
    grid-area: radiogroup;
  }
  &__date-picker {
    grid-area: datepicker;
  }
}

</style>
