export const KPIKeys = {
  IMPRESSIONS: 'IMPRESSIONS',
  CLICKS: 'CLICKS',
  CTR: 'CTR',
  AVERAGE_CPC: 'AVERAGE_CPC',
  EXPENSES: 'EXPENSES'
};

export const KPIText = {
  [KPIKeys.IMPRESSIONS]: 'Показы',
  [KPIKeys.CLICKS]: 'Клики',
  [KPIKeys.CTR]: 'CTR',
  [KPIKeys.AVERAGE_CPC]: 'Средняя цена клика',
  [KPIKeys.EXPENSES]: 'Расходы'
};