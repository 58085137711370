<template>
  <div 
    class="analytics-controls"
    :class="{ 'advanced': view === AnalyticsSubTabsKey.ACCOUNTS_AND_COMPANIES }"
  >
    <template v-if="view === AnalyticsSubTabsKey.ACCOUNTS_AND_COMPANIES">
      <AnalyticsAccountSelector class="analytics-controls__account-selector"  />
      <AnalyticsCompanySelector class="analytics-controls__company-selector" />
      <AnalyticsServiceSelector class="analytics-controls__service-selector" />
    </template>
    <RadioGroup   
      v-model="selectedOption" 
      :options="radioOptions" 
      class="analytics-controls__radio-group"
    />
    <DatePicker
      :title="$t('data_picker.title')"
      class="analytics-controls__date-picker"
      width="100%"
      @changeDates="changeDate"
    />
  </div>
</template>
  
<script>
import RadioGroup from "@/components/elements/RadioGroup";
import DatePicker from "@/components/sort/DatePicker";
import AnalyticsAccountSelector from "@/views/Analytics/AnalyticsSelectors/AnalyticsAccountSelector";
import AnalyticsCompanySelector from "@/views/Analytics/AnalyticsSelectors/AnalyticsCompanySelector";
import AnalyticsServiceSelector from "@/views/Analytics/AnalyticsSelectors/AnalyticsServiceSelector";
import { KPIKeys, KPIText } from "@/utils/common";
import { AnalyticsSubTabsKey } from "@/views/Analytics/AnalyticsHeader";

export default {
  name: 'AnalyticsControls',
  components: {
    RadioGroup,
    DatePicker,
    AnalyticsAccountSelector,
    AnalyticsCompanySelector,
    AnalyticsServiceSelector,
  },
  props: {
    view: {
      type: String,
      validator: function(value) {
        return Object.values(AnalyticsSubTabsKey).includes(value);
      }
    }
  },
  data() {
    return {
      AnalyticsSubTabsKey,
      KPIKeys,
      KPIText,
      selectedOption: KPIKeys.IMPRESSIONS,
      radioOptions: Object.keys(KPIKeys).map(key => ({
        label: KPIText[key],
        value: KPIKeys[key]
      })),
    };
  },
  computed: {
  },
  methods: {
    changeDate() {

    },
    handleSelectorChange(service) {
      console.log('Services', service)
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.analytics-controls {
  display: grid;
  grid-template-areas: 'radiogroup radiogroup datepicker';
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px 6px;
  align-items: center;
  background: $basicWhite;
  padding: 20px;
  border-radius: 4px;

  @include below(768px) {
    padding: 0;
    background: transparent;
    grid-template-areas: 
      'radiogroup' 
      'datepicker';
    grid-template-columns: 1fr;
  }

  &.advanced {
    grid-template-areas: 
      'service account company' 
      'radiogroup radiogroup datepicker';

    @include below(768px) {
      padding: 0;
      background: transparent;
      grid-template-areas: 
        'service'
        'account'
        'company'
        'radiogroup' 
        'datepicker';
      grid-template-columns: 1fr;
    }
  }

  &__service-selector {
    grid-area: service;
  }
  &__account-selector {
    grid-area: account;
  }
  &__company-selector {
    grid-area: company;
  }
  &__radio-group {
    grid-area: radiogroup;
  }
  &__date-picker {
    grid-area: datepicker;
  }
}

</style>
