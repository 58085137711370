<template>
  <div class="analytics-overview-views">
    <el-collapse v-model="active" class="analytics-overview-views__collapse">
      <el-collapse-item 
        v-for="kpiKey in KPIKeys"
        :key="kpiKey"
        class="analytics-overview-views__collapse-item" 
        :name="kpiKey"
      >
        <template #title>
          <img :src="KPIIconPath[kpiKey]" />
          <span class="analytics-overview-views__collapse-header-title">
            {{ ViewKPIText[kpiKey] }}
          </span>
          <span class="analytics-overview-views__collapse-header-count">{{numberWithSpacesCeil(312312)}}</span>
        </template>
        <ul class="analytics-overview-views__list">
          <li 
            v-for="service in Object.keys(ServiceType)"
            :key="service"
            class="analytics-overview-views__list-item"
          >
            <div class="analytics-overview-views__list-item-service-name">{{ ServiceName[service] }}</div>
            <div>{{ numberWithSpacesCeil(123123) }}</div>
          </li>
        </ul>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { KPIKeys } from "@/utils/common";
import { ServiceName, ServiceType } from "@/utils/services"
import numberWithSpacesCeil from '@/mixin/numberWithSpaces';
import IconCTR from '@/assets/icons/icon-ctr.svg';
import IconViews from '@/assets/icons/icon-views.svg';
import IconCost from '@/assets/icons/icon-cost.svg';
import IconKPI from '@/assets/icons/icon-kpi.svg';

const ViewKPIText = {
  [KPIKeys.IMPRESSIONS]: 'Все показы',
  [KPIKeys.CLICKS]: 'Клики',
  [KPIKeys.CTR]: 'CTR',
  [KPIKeys.AVERAGE_CPC]: 'Ср. цена клика',
  [KPIKeys.EXPENSES]: 'Расходы'
}

const KPIIconPath = {
  [KPIKeys.IMPRESSIONS]: IconViews,
  [KPIKeys.CLICKS]: IconViews,
  [KPIKeys.CTR]: IconCTR,
  [KPIKeys.AVERAGE_CPC]: IconKPI,
  [KPIKeys.EXPENSES]: IconCost
}

export default {
  name: 'AnalyticsOverviewViews',
  components: {
  },
  mixins: [numberWithSpacesCeil],
  data() {
    return {
      active: [],
      ServiceName, 
      ServiceType,
      KPIKeys,
      ViewKPIText,
      KPIIconPath,
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {

  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
.analytics-overview-views {
  &__collapse {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: none;
  }
  
  &__list {
    margin-top: 6px;
  }

  &__list-item {
    display: flex;
    color: $gray;
    font-weight: 400;
    font-size: 12px;
    border-bottom: 1px solid $gray7;
    padding: 8px 0;
  }

  &__list-item-service-name {
    flex: 1 1 auto;
  }

  &__collapse-item ::v-deep .analytics-overview-views__collapse-header-title {
    flex: 1 1 auto;
    color: $basicBlack;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
  }

  &__collapse-item ::v-deep .analytics-overview-views__collapse-header-count {
    color: $basicBlack;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
  }

  &__collapse-item ::v-deep .el-collapse-item__header {
    display: flex;
    gap: 12px;
    height: 33px;
    line-height: 33px;
  }

  &__collapse-item ::v-deep .el-collapse-item__arrow {
    display: none;
  }
  &__collapse-item ::v-deep .el-collapse-item__wrap,
  &__collapse-item ::v-deep .el-collapse-item__header {
    border: none;
    background: transparent;
  }
}
</style>