<template>
  <div v-if="(options && options.length) || hasSearch" class="selector" :class="{ 'selector--disabled': disabled }" v-loading="loading">
    <slot 
      :toggleShowOptions="() => showOptions = !showOptions" 
      :showOptions="showOptions"
      :selectedValue="selectedValue"
      name="trigger"
    >
      <div 
        class="selector__content" 
        @click="showOptions = !showOptions"
      >
        <div 
          v-if="title && isComponentMounted" 
          class="selector__title" 
          :class="{ 'selector__title--disabled': !selectedValue }"
        >
          {{ title }}
        </div>
        <div class="selector__value" key="origin">
          {{ selectedValue }}
        </div>
        <div class="selector__icons-group">
          <img 
            class="selector__arrow-icon"
            :class="{ 'selector__arrow-icon--open': showOptions }" 
            src="@/assets/icons/icon-arrow.svg" 
            alt="arrow"
          >
          <slot name="icons"/>
        </div>
      </div>
    </slot>
    <Transition name="fade">
      <div 
        v-if="showOptions" 
        class="selector__options" 
        :class="{'selector__options--limit-height': limitOptionsHeight}"
      > 
        <template v-if="hasSearch">
          <input 
            class="selector__search"
            type="text" 
            placeholder="Поиск"
            @input="searchValue = $event.target.value"
          >
          <div 
            v-if="!filteredOptions.length" 
            class="selector__options-not-found"
          >Ничего не найдено</div>
        </template>
        <template v-if="isMulti">
          <CheckboxOption 
            v-for="(option, index) in filteredOptions" 
            :key="index"
            :option="{ value: option.id, label: option.name }" 
            :disabled="option?.disabledOption"
            :checked="internalValue.includes(option.id)"
            class="selector__item-checkbox"
            @change="(isChecked) => handleChangeCheckbox(option, isChecked)"
          />
        </template>
        <template v-else>
          <div 
            v-for="option in filteredOptions" 
            :key="option.id" 
            class="selector__options-item"
            :class="{ 'selector__options-item--disabled': option?.disabledOption }"
            @click="chooseOption(option.name, option.id, option.email)"
          >
            {{ option.name || option.email }}
          </div>
        </template>
      </div>
    </Transition>
  </div>
</template>

<script>
import CheckboxOption from "@/components/elements/Checkbox/CheckboxOption";

export default {
  name: "Selector",
  props: {
    hasSearch: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    },
    selectFitrstItem: {
      type: Boolean,
      default: true
    },
    sum_available_in:{
      type: Number,
      default: null
    },
    ruValues: {
      type: Array,
      default: null
    },
    enValues: {
      type: Array,
      default: null
    },
    is_for_legals: {
      type: [Number, String],
      default: null
    },
    defaultSelectItemId: null,
    limitOptionsHeight: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      default: ''
    },
    fileType: {
      type: String,
      default: ''
    },
    selected : {
      type: [String, Number],
      default: null
    },
    isMulti: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  components: {
    CheckboxOption,
  },
  data() {
    return {
      loading: false,
      selectedValue: this.selectFitrstItem ? (this.options[0].name || this.options[0].email) : null,
      showOptions: false,
      searchValue: "",
      internalValue: [],
      isComponentMounted: false
    }
  },
  computed: {
    filteredOptions() {
      if(this.hasSearch) {
        return this.options.filter((option) => option.name.toLowerCase().includes(this.searchValue.toLowerCase()))
      }
      return this.options
    },
    getLang() {
      let language = this.$i18n._vm.locale
      return language
    }
  },
  watch: {
    getLang: {
      deep: true,
      handler() {
        this.translateSelectedValue()
      }
    },
    defaultSelectItemId: {
      handler(value) {
        if (value) {
          const option = this.options.find((option) => {
            return option.id === value
          })
          if (option) {
            this.selectedValue = option.name
          }
          this.showOptions = false
        } else {
          this.selectedValue = null
        }
      },
      immediate: true,
      deep: true
    },
    fileType(value) {
      if(value) {
        console.log(value)
        this.selectedValue =''
      }
    },
    selected: {
      handler(val) {
        const option = this.options.find((item) => item.id === val)
        
        if(option) {
          this.selectedValue = option.name || option.email
        }
      },
      immediate: true 
    }
  },
  methods: {
    updateSelectedValue() {
      if(this.sum_available_in) {
        this.selectedValue = this.options.find((item) => item.id === this.sum_available_in).name
      }
      if(this.is_for_legals === 0) {
        this.selectedValue = this.options.find((item) => item.id === this.is_for_legals).name
      }
      if(this.is_for_legals) {
        this.selectedValue = this.options.find((item) => item.id === this.is_for_legals).name
      }
    },
    translateSelectedValue() {
      if (this.enValues && this.ruValues) {
        if (this.$i18n._vm.locale === 'ru' && this.enValues.includes(this.selectedValue)) {
          this.selectedValue = this.ruValues[this.enValues.indexOf(this.selectedValue)]
        } else if (this.$i18n._vm.locale === 'en' && this.ruValues.includes(this.selectedValue)) {
          this.selectedValue = this.enValues[this.ruValues.indexOf(this.selectedValue)]
        }
      }
      return this.selectedValue
    },
    handleChangeCheckbox(option, isChecked) {      
      if (isChecked) {
        this.internalValue.push(option.id);
      } else {
        const index = this.internalValue.indexOf(option.id);
        if (index > -1) {
          this.internalValue.splice(index, 1);
        }
      }
      this.selectedValue = this.options
        .filter((option) => this.internalValue.includes(option.id))
        .map((option) => option.name)
        .join(', ')

      this.$emit('onChange', this.internalValue);
    },
    chooseOption(name, id, email) {
      this.selectedValue = name ? name : email
      this.showOptions = false
      this.$emit('handleChange', id, name)
    },
    serActiveName(name) {
      this.selectedValue = name
    },
    setSelectedValue(val) {
      const option = this.options.find((item)=> {
        return item.id === val
      })
      if(option) {
        this.selectedValue = option.name || option.email
      }
    }
  },
  mounted() {
    this.isComponentMounted = true

    this.internalValue = this.value;
    this.selectedValue = this.options
        .filter((option) => this.internalValue.includes(option.id))
        .map((option) => option.name)
        .join(', ')
        

    this.updateSelectedValue()
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.selector')) {
        this.showOptions = false
      }
    }, true)
    this.translateSelectedValue()
    this.$nextTick(() => {
      if (this.selectFitrstItem && this.origin ==='documents') {
        this.serActiveName(this.options[0].name)
      this.$emit('handleChange', this.options[0].id)
      }
    })
    this.$nextTick(() => { 
      if(this.selected) {
        this.setSelectedValue(this.selected);
      }
    })
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixin";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.selector {
  position: relative;
  user-select: none;

  &--disabled {
    pointer-events: none;
    opacity: .5;
  }

  &__search {
    height: 3.5rem;
    padding: 0 1.5rem;
    width: 100%;
    border: none;
  }

  &__content {
    // background: $backgroundInput;
    border: 1px solid $border;
    border-radius: 2px;
    height: 5rem;
    position: relative;
    padding: 0.7rem 1.5rem;
    min-width: 22rem;
    width: 100%;
  }

  &__title {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $textGrey;
    margin-bottom: 0.2rem;
    transition: all .2s;

    &--disabled {
      position: relative;
      transform: translateY(11px);
      font-size: 1.6rem;
    }
  }

  &__value {
    font-size: 1.6rem;
    line-height: 1.9rem;
    @include lineClamp(1);
    padding-right: 10px;
    line-break: anywhere;
  }

  &__icons-group {
    display: flex;
    gap: 1rem;
    right: 1.5rem;
    top: 1.5rem;
    position: absolute;
  }

  &__arrow-icon {
    cursor: pointer;
    transition: 0.3s ease-out;

    &--open {
      transition: 0.3s ease-out;
      transform: rotate(180deg);
    }

    // &__options {
    //   position: absolute;
    //   top: calc(100% + 1rem);
    //   left: 0;
    //   width: 100%;
    //   background: $backgroundInput;
    //   border: 1px solid $border;
    //   border-radius: 2px;
    //   z-index: 1;

    //   &-item {
    //     cursor: pointer;
    //     transition: 0.3s ease-out;
    //     padding: 0.7rem 1.5rem;

    //     &:hover {
    //       background: var(--primary);
    //       color: $basicWhite;
    //     }
    //   }
    // }

    @include below(769px) {

      &__content {
        width: 100%;
        transform: rotate(180deg);
      }
    }
  }

  &__options {
    position: absolute;
    top: calc(100% + .6rem);
    left: 0;
    width: 100%;
    background: $backgroundInput;
    border: 1px solid $border;
    border-radius: 2px;
    z-index: 999;

    &--limit-height {
      max-height: 20rem;
      overflow: auto;
    }

    &-not-found {
      padding: 0.5rem 1.5rem;
    }

    &-item {
      cursor: pointer;
      transition: 0.3s ease-out;
      padding: 0.5rem 1.5rem;

      &--disabled {
        pointer-events: none;
        opacity: .5;
      }

      &:not(.is-multi):hover {
        background: var(--primary);
        color: $basicWhite;
      }
    }

    &-search {
      &.form-input {
        width: calc(100% - 20px);
        margin: 10px;
      }
    }
  }

  &__item-checkbox {
    width: 100%;
    padding: 10px 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18.75px;
    color: $basicBlack;
    margin-right: 0;
    transition: background 100ms ease;

    &:hover, &.is-checked {
      background-color: $primary;
      color: $basicWhite;
    }
  }

  @include below(769px) {

    &__content {
      width: 100%;
    }
    &__options {
      width: 100%;
    }
  }
}
</style>
