var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-overview"},[_c('AnalyticsControls',{attrs:{"view":_vm.AnalyticsSubTabsKey.VIEW}}),_c('div',{staticClass:"analytics-overview__container"},[_c('div',{staticClass:"analytics-overview__block"},[_c('div',{staticClass:"analytics-overview__title"},[_vm._v("Показы")]),_c('AnalyticsOverviewViews',{staticClass:"analytics-overview__views"})],1),_c('div',{staticClass:"analytics-overview__block"},[_c('div',{staticClass:"analytics-overview__title"},[_vm._v("Все показы")]),_c('AnalyticsOverviewDoughnutChart',{staticClass:"analytics-overview__doughnut-chart",attrs:{"dataDoughnutChart":_vm.chartData,"totalNumber":_vm.totalNumber,"totalPercent":_vm.totalPercent,"dataList":_vm.dataList}})],1)]),_c('AnalyticsLineChart',{staticClass:"analytics-overview__line-chart",attrs:{"title":"Мой график","subTitle":"Отображение площадок на графике:","options":[
      { label: 'VK', value: 'vk' },
      { label: 'Yandex', value: 'yandex' }
    ],"lineDataChart":{
      labels: ['01.03.2025', '02.03.2025', '03.03.2025', '04.03.2025'],
      datasets: [
        {
          label: 'VK',
          data: [100, 200, 12, 123],
          borderColor: '#FF974C',
          backgroundColor: 'transparent'
        },
        {
          label: 'Yandex',
          data: [22, 144, 444, 222],
          borderColor: 'red',
          backgroundColor: 'transparent'
        }
      ]
    },"platforms":[
      { name: 'vk', label: 'vk.com', icon: require('@/assets/icons/icon-vk-graph.svg') },
      { name: 'yandex', label: 'yandex', icon: require('@/assets/icons/icon-yandex-graph.svg') }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }