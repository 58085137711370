<template>
  <div class="analytics-detailed-stats-panel">
    <div 
      v-for="item in data"
      :key="item.id"
      class="analytics-detailed-stats-panel__item"
    >
        <span class="analytics-detailed-stats-panel__label">{{ item.label }}</span>
        <span class="analytics-detailed-stats-panel__value">{{ item.value }}</span>
        <span class="analytics-detailed-stats-panel__change analytics-detailed-stats-panel__change--positive">{{ item.change }}</span>
    </div>
</div>
</template>
<script>
import { AnalyticsSubTabsKey } from "@/views/Analytics/AnalyticsHeader";

export default {
  name: 'AnalyticsDetailedStatsPanel',
  components: {
  },
  props: {
    data: {
      type: Array,
      defailt: () => []
    }
  },
  data() {
    return {
      AnalyticsSubTabsKey
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {

  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.analytics-detailed-stats-panel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;

  @include below(768px) {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    padding-block: 20px;
    background: $basicWhite;
  }

  &__label {
    margin-bottom: 24px;
    font-size: 16px;
    color: $basicBlack;
    text-align: center;
  }

  &__value {
    color: $basicBlack;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  &__change {
    font-size: 16px;

    &--neutral {
      color: $redLight;
    }
    &--positive {
      color: $greenClassic;
    }
  }
}
</style>