<template>
  <div class="analytics-header">
    <Tabs 
      v-model="activeMainTab" 
      :tabs="mainTabs" 
    />
    <Tabs 
      v-model="activeSubTab"
      type="card"
      :tabs="tabs" 
    />
  </div>
</template>
  
<script>
import Tabs from "@/components/elements/Tabs";

const MainTabsKey = {
  STATISTICS: 'STATISTICS',
  ANALYTICS: 'ANALYTICS'
}

const MainTabsText = {
  [MainTabsKey.STATISTICS]: 'Статистика',
  [MainTabsKey.ANALYTICS]: 'Аналитика',
}

export const AnalyticsSubTabsKey = {
  VIEW: 'VIEW',
  ACCOUNTS_AND_COMPANIES: 'ACCOUNTS_AND_COMPANIES'
}

const AnalyticsSubTabsText = {
  [AnalyticsSubTabsKey.VIEW]: 'Обзор',
  [AnalyticsSubTabsKey.ACCOUNTS_AND_COMPANIES]: 'Аккаунты и компания',
}

export default {
  name: 'AnalyticsHeader',
  components: {
    Tabs,
  },
  data: () => ({
    mainTabs: [
      {
        name: MainTabsKey.STATISTICS,
        label: MainTabsText[MainTabsKey.STATISTICS]
      },
      {
        name: MainTabsKey.ANALYTICS,
        label: MainTabsText[MainTabsKey.ANALYTICS]
      },
    ],
    tabs: [
      {
        name: AnalyticsSubTabsKey.VIEW,
        label: AnalyticsSubTabsText[AnalyticsSubTabsKey.VIEW]
      },
      {
        name: AnalyticsSubTabsKey.ACCOUNTS_AND_COMPANIES,
        label: AnalyticsSubTabsText[AnalyticsSubTabsKey.ACCOUNTS_AND_COMPANIES]
      },
    ],
    activeSubTab: AnalyticsSubTabsKey.VIEW,
    activeMainTab: MainTabsKey.ANALYTICS
  }),
  watch: {
    activeMainTab(val) {
      if (val ===  MainTabsKey.STATISTICS) {
        this.$router.push({ name: 'Home' })
      }
    },
    activeSubTab(val) {
      this.$emit('onChangeSubTab', val)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
.analytics-header {
  display: flex;
  flex-direction: column;
  gap: 34px;
}

</style>
