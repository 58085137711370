<template>
  <div id="app" :style="styleTheme">
    <component :is="layout">
      <router-view />
    </component>
    <Footer v-if="$store.getters.language === 'ru' && routeName!== 'RegistrationPromotion'"/>
  </div>
</template>

<script>
import axios from 'axios'
import Footer from "@/components/blocks/Footer";

export default {
  name: 'App',
  metaInfo: {
    // title: `${process.env.VUE_APP_LOCALE==='USA'?'Digital Eagle':'ProfitAds'}`,
    title: `${process.env.VUE_APP_API_URL==='http://158.160.13.3:8081/api/v1'?`${process.env.VUE_APP_LOCALE==='USA'?'TEST_Digital Eagle':'TEST_ProfitAds'}`:`${process.env.VUE_APP_LOCALE==='USA'?'Digital Eagle':'ProfitAds'}`}`,
    link: [
      // {rel:'icon', href:`favicon-${process.env.VUE_APP_LOCALE}.ico`},
      {rel:'icon', href:`${process.env.VUE_APP_API_URL==='http://158.160.13.3:8081/api/v1'?`favicon_test-${process.env.VUE_APP_LOCALE}.ico`:`favicon-${process.env.VUE_APP_LOCALE}.ico`}`},
      {rel:'apple-touch-icon',  sizes:"180x180", href:`apple-touch-icon-${process.env.VUE_APP_LOCALE}.png`},
      {rel:'apple-touch-icon',type:"image/png",  sizes:"32x32", href:`favicon-${process.env.VUE_APP_LOCALE}-32x32.png`},
      {rel:'apple-touch-icon',type:"image/png",  sizes:"16x16", href:`favicon-${process.env.VUE_APP_LOCALE}-16x16.png`},
    ]
  },
  components: {
    Footer,
  },
  data() {
    return {
      colors: {},
      locale: process.env.VUE_APP_LOCALE,
      styleTheme: {
        "--primary": this.$colors().primary,
        "--primaryLight": this.$colors().primaryLight,
        "--primaryHover": this.$colors().primaryHover,
        "--gray": this.$colors().gray,
        "--black": this.$colors().black,
        "--bgGray": this.$colors().bgGray
      }
    }
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'base-layout'
    },
    user() {
      return this.$store.state.Auth.user
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    routeName() {
      return this.$route.name
    },
  },
  async mounted() {
    try {
      // Initialize language
      this.$store.commit('initLocalLang', this.$i18n._vm.locale);
      
      // Get tokens from storage
      const token = localStorage.getItem('token');
      const clientToken = localStorage.getItem('client_token');
      const activeClient = localStorage.getItem('active_client');
      
      // Set colors early since they don't depend on async operations
      this.colors = this.$colors();
      
      if (token) {
        // Handle primary token flow
        this.$store.commit('Auth/auth_success', token);
        
        // Parallelize independent requests when possible
        await Promise.all([
          this.$store.dispatch('Representatives/getPermissions', { token }),
          this.$store.dispatch('Auth/getUser', { token })
        ]);
        
        if (clientToken && activeClient) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${clientToken}`;
          await this.$store.dispatch('Auth/getBusinessAccount', activeClient);
        }
      } else if (clientToken) {
        // Handle client token flow
        axios.defaults.headers.common['Authorization'] = `Bearer ${clientToken}`;
        this.$store.commit('Auth/auth_success', clientToken);
        
        await Promise.all([
          this.$store.dispatch('Auth/getUser', { token: clientToken }),
          this.$store.dispatch('Representatives/getPermissions', { token: clientToken })
        ]);
      }
    } catch (error) {
      console.error('Initialization error:', error);
      // Consider adding error handling (e.g., show user notification)
    }
  },
  methods: {
    getBallance() {
      this.$store.dispatch('Auth/getBallance', this.activeAccount.id)
    },
  },
}
</script>


<style lang="scss">
@import "@/assets/styles/helpers/mixin";

#app {
  --primary: $primary;
  --primaryLight: $primaryPale;
  --primaryHover: $primaryHover;
  --gray: #E6EAF2;
  --black: #000;
}

.app {

  &-pages {
    max-width: 133rem;
    display: flex;
    padding: 8rem 1.5rem 6rem;
    margin: 0 auto;

    &__row {
      width: 100%;
      display: flex;
      margin: 0 -1.5rem;
    }
  }

  &-sidebar {
    width: 30%;
    flex-shrink: 0;
    padding: 0 1.5rem;
  }

  &-content {
    flex: 1;
    padding: 0 1.5rem;
    min-width: 0;
  }

  &-no-data {
    display: flex;
    justify-content: center;
    gap: 4rem;
    align-items: flex-start;
    padding: 5.5rem 6rem;
    width: 100%;
    text-align: center;

    &__title {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.3rem;
      margin-bottom: 1.2rem;
    }

    &__notice {
      color: #7F858D;
      font-family: "Roboto", sans-serif;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 36rem;
      margin: 1rem auto 0;
      text-align: left; 
    }

    &__text {
      max-width: 36rem;
      margin: 0 auto 3.2rem;
    }

    &__button {
      padding: 1.2rem 4.2rem;
      height: auto !important;
      font-weight: 400 !important;
    }

    span {
      color: #2D52D2;
      cursor: pointer;
    }
  }
  &-add-business-account, &-became-referral {
    padding: 4rem;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 5px 10px 0px rgba(158, 158, 158, 0.10);
  }
  @include below(993px) {
    &-pages__row {
      flex-wrap: wrap;
    }

    &-pages {
      max-width: 100%;
      padding: 60px 16px;

      &__row {
        margin: 0 auto;
      }
    }

    &-content {
      flex: unset;
      width: 100%;
      padding: 0;
    }

    &-sidebar {
      width: 100%;
      padding: 0;
      margin-bottom: 32px;
    }
  }

  @include below(769px) {
    &-pages {
      max-width: 100%;
      padding-top: 32px;
      padding-bottom: 32px;
    }

    &-sidebar {
      margin-bottom: 24px;
    }
  }
}
</style>
