<template>
  <el-checkbox 
    class="checkbox"
    :label="label" 
    :value="value" 
  />
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
   
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
.checkbox {
  margin-right: 20px;

  .el-checkbox {

    &__input {
      color: $basicBlack;
    }

    &__inner {
      border-color: $gray3;
      width: 16px;
      height: 16px;
      border-radius: 4px;

      &::after {
        height: 7px;
        left: 5px;
        top: 2px;
      }
    }

    &__label {
      padding-left: 8px;
      font-size: 14px;
      font-weight: 400;
      color: $basicBlack;
    }

    &__input.is-checked .el-checkbox__inner {
      background-color: $primary;
      border-color: $basicWhite;
    }

    &__input.is-checked + .el-checkbox__label {
      color: $basicBlack;
    }
  }
}
</style>