<template>
  <div class="analytics-overview">
    <AnalyticsControls :view="AnalyticsSubTabsKey.VIEW" />
    <div class="analytics-overview__container">
      <div class="analytics-overview__block">
        <div class="analytics-overview__title">Показы</div>
        <AnalyticsOverviewViews 
          class="analytics-overview__views"
        />
      </div>
      <div class="analytics-overview__block">
        <div class="analytics-overview__title">Все показы</div>
        <AnalyticsOverviewDoughnutChart 
          class="analytics-overview__doughnut-chart"
          :dataDoughnutChart="chartData"
          :totalNumber="totalNumber"
          :totalPercent="totalPercent"
          :dataList="dataList"
        />
      </div>
    </div>
    <AnalyticsLineChart 
      class="analytics-overview__line-chart"
      title="Мой график"
      subTitle="Отображение площадок на графике:"
      :options="[
        { label: 'VK', value: 'vk' },
        { label: 'Yandex', value: 'yandex' }
      ]"
      :lineDataChart="{
        labels: ['01.03.2025', '02.03.2025', '03.03.2025', '04.03.2025'],
        datasets: [
          {
            label: 'VK',
            data: [100, 200, 12, 123],
            borderColor: '#FF974C',
            backgroundColor: 'transparent'
          },
          {
            label: 'Yandex',
            data: [22, 144, 444, 222],
            borderColor: 'red',
            backgroundColor: 'transparent'
          }
        ]
      }"
      :platforms="[
        { name: 'vk', label: 'vk.com', icon: require('@/assets/icons/icon-vk-graph.svg') },
        { name: 'yandex', label: 'yandex', icon: require('@/assets/icons/icon-yandex-graph.svg') }
      ]" 
    />
  </div>

  <!-- <div class="analytics-overview-line-chart__platforms-item vk">
          <img src="@/assets/icons/icon-vk-graph.svg" class="graph-icon">
          vk.com  
        </div>
        <div class="analytics-overview-line-chart__platforms-item yandex">
          <img src="@/assets/icons/icon-yandex-graph.svg" class="graph-icon">
          yandex
        </div>
        <div class="analytics-overview-line-chart__platforms-item facebook">
          <img src="@/assets/icons/icon-facebook-graph.svg" class="graph-icon">
          facebook
        </div>
        <div class="analytics-overview-line-chart__platforms-item mytarget">
          <img src="@/assets/icons/icon-mytarget-graph.svg" class="graph-icon">
          myTarget
        </div>
        <div class="analytics-overview-line-chart__platforms-item mytarget">
          <img src="@/assets/icons/icon-vk-graph.svg" class="graph-icon">
          vkads
        </div>
        <div class="analytics-overview-line-chart__platforms-item mytarget">
          <img src="@/assets/icons/icon-yandex-graph.svg" class="graph-icon">
          google
        </div>
        <div class="analytics-overview-line-chart__platforms-item telegram">
          <img src="@/assets/icons/icon-telegram-graph.svg" class="graph-icon">
          telegram
        </div> -->
</template>
<script>
import AnalyticsControls from "@/views/Analytics/AnalyticsControls";
import { AnalyticsSubTabsKey } from "@/views/Analytics/AnalyticsHeader";
import AnalyticsOverviewViews from "@/views/Analytics/AnalyticsOverview/AnalyticsOverviewViews";
import AnalyticsOverviewDoughnutChart from "@/views/Analytics/AnalyticsOverview/AnalyticsOverviewDoughnutChart";
import AnalyticsLineChart from "@/views/Analytics/AnalyticsLineChart";

export default {
  name: 'AnalyticsOverview',
  components: {
    AnalyticsControls,
    AnalyticsOverviewViews,
    AnalyticsOverviewDoughnutChart,
    AnalyticsLineChart,
  },
  data() {
    return {
      AnalyticsSubTabsKey,


      chartData: {
        // Example data structure for the doughnut chart
        labels: ['Service A', 'Service B', 'Service C'],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
          }
        ]
      },
      totalNumber: 450,
      totalPercent: -10, // Example percentage
      dataList: [
        { service: 'Service A', count: 300, percent: 66.67, color: '#FF6384' },
        { service: 'Service B', count: 50, percent: 11.11, color: '#36A2EB' },
        { service: 'Service C', count: 100, percent: 22.22, color: '#FFCE56' }
      ]
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {

  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.analytics-overview {
  &__line-chart {
    margin-top: 40px;
  }
  &__block {
    width: 100%;
  }
  &__views {
    width: 100%;
    max-width: 376px;

    @include below(768px) {
      max-width: 100%;
    }
  }
  &__doughnut-chart {
    width: 100%;
    max-width: 450px;

    @include below(768px) {
      max-width: 100%;
    }
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    margin-top: 20px;
    
    @include below(768px) {
      grid-template-columns: 1fr;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    color: $basicBlack;
    margin-bottom: 30px;
  }
}
</style>