<template>
  <div class="analytics-detailed">
    <AnalyticsControls :view="AnalyticsSubTabsKey.ACCOUNTS_AND_COMPANIES" />
    <AnalyticsDetailedStatsPanel 
      class="analytics-detailed__stats-panel"
      :data="analyticsStatsData"
    />
    <AnalyticsLineChart 
      class="analytics-detailed__line-chart"
      title="Динамика изменения Показов "
      subTitle="Отображение аккаунтов на графике:"
      :lineDataChart="{
        labels: ['01.03.2025', '02.03.2025', '03.03.2025', '04.03.2025'],
        datasets: [
          {
            label: 'VK',
            data: [100, 200, 12, 123],
            borderColor: '#FF974C',
            backgroundColor: 'transparent'
          },
          {
            label: 'Yandex',
            data: [22, 144, 444, 222],
            borderColor: 'red',
            backgroundColor: 'transparent'
          }
        ]
      }"
      :platforms="[
        { name: 'vk', label: 'vk.com/gektor_pro', icon: require('@/assets/icons/icon-vk-graph.svg') },
        { name: 'yandex', label: 'yandex/gektor_pro', icon: require('@/assets/icons/icon-yandex-graph.svg') }
      ]" 
    />
  </div>
</template>
<script>
import AnalyticsControls from "@/views/Analytics/AnalyticsControls";
import AnalyticsDetailedStatsPanel from "@/views/Analytics/AnalyticsDetailed/AnalyticsDetailedStatsPanel";
import { AnalyticsSubTabsKey } from "@/views/Analytics/AnalyticsHeader";
import AnalyticsLineChart from "@/views/Analytics/AnalyticsLineChart";

export default {
  name: 'AnalyticsDetailed',
  components: {
    AnalyticsControls,
    AnalyticsDetailedStatsPanel,
    AnalyticsLineChart,
  },
  data() {
    return {
      AnalyticsSubTabsKey
    };
  },
  computed: {
    analyticsStatsData() {
      return [
        {
          label: 'Показы',
          value: '1 000 886 276',
          change: '+ 23,34%'
        },
        {
          label: 'Клики',
          value: '2 400',
          change: '+ 23,34%'
        },
        {
          label: 'Ср. цена клика',
          value: '1 886 276',
          change: '+ 23,34%'
        },
        {
          label: 'CTR',
          value: '1 276',
          change: '+ 23,34%'
        },
        {
          label: 'Расходы',
          value: '1 000',
          change: '+ 23,34%'
        }
      ]
    }
  },
  methods: {
  },
  mounted() {

  }
}
</script>
<style lang="scss" scoped>
.analytics-detailed {
  &__stats-panel {
    margin-top: 20px;
  }

  &__line-chart {
    margin-top: 40px;
  }
}
</style>