<template>
  <Selector
    class="analytics-company-selector"
    required
    title="Выберите компанию"
    isMulti
    limitOptionsHeight
    :options="serviceOptions"
    :value="checkedValues"
    :select-fitrst-item="false"
    @onChange="handleSelectorChange"
  />
</template>
  
<script>
import Selector from "@/components/sort/Selector";
import { ServiceType, ServiceName } from "@/utils/services";
import { KPIKeys, KPIText } from "@/utils/common";

export default {
  name: 'AnalyticsControls',
  components: {
    Selector,
  },
  data() {
    return {
      KPIKeys,
      KPIText,
      ServiceType, 
      ServiceName,
      checkedValues: [],
      selectedOption: KPIKeys.IMPRESSIONS,
      radioOptions: Object.keys(KPIKeys).map(key => ({
        label: KPIText[KPIKeys[key]],
        value: KPIKeys[key]
      }))
    };
  },
  computed: {
    serviceOptions() {
      return Object.keys(ServiceType).map((serviceType) => ({ id: serviceType, name: ServiceName[serviceType] }))
    }
  },
  methods: {
    changeDate() {

    },
    handleSelectorChange(service) {
      console.log('Services', service)
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
.analytics-controls {
  display: grid;
  grid-template-areas: 'service account company' 'radiogroup radiogroup datepicker';
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  align-items: center;

  &__service-selector {
    grid-area: service;
  }
  &__account-selector {
    grid-area: account;
  }
  &__company-selector {
    grid-area: company;
  }
  &__radio-group {
    grid-area: radiogroup;
  }
  &__date-picker {
    grid-area: datepicker;
  }
}

</style>
