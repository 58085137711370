export const UserRole = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  FINANCE: 'finance',
  USER: 'user',
  AGENT: 'agent',
  REFERRAL: 'referral',
  PARTNER: 'partner',
}

export const UserRoleText = {
  [UserRole.ADMIN]: 'Админ',
  [UserRole.MANAGER]: 'Менеджер',
  [UserRole.FINANCE]: 'Финансист',
  [UserRole.USER]: 'Клиент',
  [UserRole.AGENT]: 'Агент',
  [UserRole.REFERRAL]: 'Реферал',
  [UserRole.PARTNER]: 'Представитель',
}