<template>
  <div class="clients-info" :class="{ 'locale-usa': locale === 'USA' }">
    <div class="container">
      <div class="clients-info__header">
        <div class="clients-info__title">
          {{ $t('users.title') }}
        </div>
        <div v-if="clientRole === 'admin'" class="users__add" @click="addNewUser">
          + {{ $t('users.add_user') }}
        </div>
      </div>
      <div class="clients-info__filters">
        <Search 
          :value="searchValue"
          placeholder="Email / ID" 
          @findValue="changeSearchText" 
        />
        <Selector 
          v-if="clientRole === 'admin' || clientRole === 'manager'" 
          :title="$t('users.role')"
          :selected="selectedRole"
          :options="locale === 'USA' ? clientTypesUSA : clientTypes" 
          @handleChange="getNewData" 
        />
        <button :title="$t('button_props.generate_report')" class="clients-info__download"
          @click="getAllUsersList()"></button>
      </div>
      <div v-if="usersList && usersList.length" class="clients-info__table" v-loading="loading">
        <el-table :data="usersList" :row-class-name="tableRowClassName" style="width: 100%" :fit="true">

          <el-table-column width="200" class-name="clients-info__table-id" label="ID">
            <template slot-scope="scope">
              <span class="clients__table-id__short" :class="{ 'locale-usa': locale === 'USA' }"
                @click="copyId(scope.row.id)">
                <span>
                  {{ scope.row.id }}
                </span>
              </span>

              <br>
              <span class="clients__table-date">
                {{ fixDateFormat(scope.row.created_at) }}
              </span>
            </template>
          </el-table-column>

          <el-table-column v-if="clientRole === 'admin'" prop="role_ru" :label="$t('users.role')" width="120">
            <template slot-scope="scope">
              {{ scope.row.role }}
            </template>

          </el-table-column>

          <el-table-column :label="$t('users.table_lable_mail')">
            <template v-if="scope.row.email ? scope.row.email : ''" slot-scope="scope">
              <a :href="'mailto:' + scope.row.email">
                {{ scope.row.email }}
              </a>
            </template>
          </el-table-column>

          <!-- <el-table-column label="Телефон">
            <template slot-scope="scope">
              <div class="clients-info__table-phone">
                <span class="icon-holding" v-if="scope.row.is_holding === 1">
                  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5.72727 10.2836L1.43182 6.20896L0 7.56716L5.72727 13L18 1.35821L16.5682 0L5.72727 10.2836Z"
                      fill="#395FE4" />
                  </svg>
                </span>
              </div>
            </template>
          </el-table-column> -->

          <el-table-column label="Телефон">
            <template slot-scope="scope">
              {{ scope.row.phone }}
            </template>
          </el-table-column>
          <el-table-column width="150" label="Партнер" :class-name="customCellClass">
            <template slot-scope="scope">
              {{ scope.row.partner }}
            </template>
          </el-table-column>
          <el-table-column width="100" label="Агент" :class-name="customCellClass">
            <template slot-scope="scope">
              <a 
                v-if="scope.row.is_agent" 
                @click="openAgentReferenceListDialog(scope.row.agent.id)" 
                style="cursor: pointer"
              >Агент</a>
              <a 
                v-if="scope.row.ref_link_id && !scope.row.is_agent" 
                @click="openAgentReferenceListDialog(scope.row.agent_id, true)" 
                style="cursor: pointer"
              >Реферал</a>
            </template>
          </el-table-column>
          
          <el-table-column :class-name="'created_at-column'" label="Дата создания">
            <template slot-scope="scope">
              {{ fixDateFormat(scope.row.created_at) }}
            </template>
          </el-table-column>

          <el-table-column width="190">
            <template slot-scope="scope">
              <span v-if="scope.row.is_active === 0" class="clients-info__table-deactivated">{{ $t('users.deactivated')
                }}</span>
              <button v-else class="clients-info__table-button" @click="addBusinessAccount(scope.row.id)"
                v-html="$t('users.add_account')">

              </button>
            </template>
          </el-table-column>

          <el-table-column class-name="clients-info__table-edit" width="30">
            <template slot-scope="scope">

              <span class="clients-info__table-edit__btn" @click="changeHolding(scope.row)">
                <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM3 8.5C3 9.32843 2.32843 10 1.5 10C0.671573 10 0 9.32843 0 8.5C0 7.67157 0.671573 7 1.5 7C2.32843 7 3 7.67157 3 8.5ZM1.5 17C2.32843 17 3 16.3284 3 15.5C3 14.6716 2.32843 14 1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17Z"
                    fill="#9BA2AB" />
                </svg>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="usersList && !usersList.length">
        {{ $t('nothing_found') }}
      </div>
      <div class="clients-info__pagination">
        <el-pagination v-if="pagination && pagination.total >= 10" layout="prev, pager, next" :total="pagination.total"
          :page-size="10" :current-page="pagination.currentPage" @current-change="getNewPage" />
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/sort/Search";
import Selector from "@/components/sort/Selector";
import fixDateFormat from '@/mixin/fixDate'
import { UserRoleText, UserRole } from "@/utils/user";

const ALL_ROLES_ID = 0

export default {
  name: 'ClientsInfo',
  components: {
    Search,
    Selector
  },
  mixins: [fixDateFormat],
  data() {
    return {
      UserRoleText,
      UserRole,
      locale: process.env.VUE_APP_LOCALE,
      loading: false,
      params: {
        // role: 'user',
        per_page: 10,
      },
      role: {
        finance: 'Финансист',
        admin: 'Админ',
        manager: 'Менеджер',
        user: 'Клиент',
      },
      roleUSA: {
        finance: 'Financier',
        admin: 'Admin',
        manager: 'Manager',
        user: 'Client',
      },
      clientTypesUSA: [
        {
          id: 0,
          name: 'All'
        },
        {
          id: 'finance',
          name: 'Financier'
        },
        {
          id: 'manager',
          name: 'Manager'
        },
        {
          id: 'admin',
          name: 'Admin'
        },
        {
          id: 'user',
          name: 'Client'
        },
      ],
    }
  },
  computed: {
    searchValue() {
      const { search } = this.$route.query
      return search || ''
    },
    selectedRole() {
      const { role } = this.$route.query
      return role || ALL_ROLES_ID
    },
    clientTypes() {
      return [
        {
          id: ALL_ROLES_ID,
          name: 'Все'
        },
        ...(this.clientRole === UserRole.ADMIN ? [{ id: UserRole.FINANCE, name: UserRoleText[UserRole.FINANCE] }] : []),
        ...(this.clientRole === UserRole.ADMIN ? [{ id: UserRole.MANAGER, name: UserRoleText[UserRole.MANAGER] }] : []),
        ...(this.clientRole === UserRole.ADMIN ? [{ id: UserRole.ADMIN, name: UserRoleText[UserRole.ADMIN] }] : []),
        {
          id: UserRole.USER,
          name: UserRoleText[UserRole.USER]
        },
        {
          id: UserRole.AGENT,
          name: UserRoleText[UserRole.AGENT]
        },
        {
          id: UserRole.REFERRAL,
          name: UserRoleText[UserRole.REFERRAL]
        },
        {
          id: UserRole.PARTNER,
          name: UserRoleText[UserRole.PARTNER]
        }
      ]
    },
    usersList() {
      return this.$store.state.Users.usersAllList
    },
    pagination() {
      return this.$store.state.Users.pagination
    },
    clientRole() {
      return this.$store.state.Auth.user.role
    },
    customCellClass() {
      return 'partner-column'
    },
    dialogVisible() {
      return this.$store.state.Dialog.dialogVisible
    }
  },
  watch: {
    $route(newVal) {
      const queries = newVal.query
      this.params = {}

      for (let key in queries) {
        this.params[key] = queries[key]
      }
      
      if (!this.dialogVisible) {
        this.getData()
      }
    }
  },
  created() {
    this.$store.commit('Auth/setData', { label: 'activeAccount', data: null })
    for (let key in this.$route.query) {
      this.params[key] = this.$route.query[key]
    }
    this.$nextTick(() => {
      this.getData()
    })
  },
  methods: {
    openAgentReferenceListDialog(agent_id, forceGetUserByAgentId = false) {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AgentReferenceList' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      this.$router.push({ query: { ...this.$route.query, agent_id, force: forceGetUserByAgentId }})
    },
    addNewUser() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddEditUser' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    async getData() {
      this.loading = true
      const token = localStorage.getItem('token')
      await this.$store.dispatch('Users/getAllUsersList', { params: this.params, token })
      this.loading = false
    },
    changeSearchText(val) {
      if (!val) {
        const { search, email, ...rest } = this.$route.query
        const query = { ...rest, page: 1 }
        this.$router.push({ query }).catch(() => {})
      } else {
        const query = { ...this.$route.query, page: 1, search: val }
        this.$router.push({ query }).catch(() => {})
      }
    },
    getNewPage(page) {
      const query = { ...this.$route.query, page }
      this.$router.push({ query }).catch(() => {})
    },
    getNewData(val) {
      if (typeof val === 'string') {
        const { is_legal, ...rest } = this.$route.query
        const query = { ...rest, page: 1, role: val }
        this.$router.push({ query }).catch(() => {})
      } else {
        if (val === 0) {
          const query = { ...this.$route.query, page: 1, role: null }
          this.$router.push({ query }).catch(() => {})
        } else {
          const role = val
          const query = { ...this.$route.query, page: 1, role }
          this.$router.push({ query }).catch(() => {})
        }
      }
    },
    addBusinessAccount(id) {
      if (this.clientRole === 'manager' || this.clientRole === 'finance') {
        this.$store.commit('Dialog/setData', { label: 'isEditable', data: true })
      }
      this.$store.commit('Dialog/setData', { label: 'userIdForAccount', data: id })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'BusinessAccountData' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },

    getAllUsersList() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'GetUsersXLS' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    changeHolding(user) {
      this.$store.commit('Users/setData', { label: 'editUser', data: user })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddEditUser' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    tableRowClassName({ row }) {
      if (row.is_active === 0) {
        return 'el-table__row--deactivated';
      }
      return '';
    },
    copyId(id) {
      navigator.clipboard.writeText(id)
        .then(() => {
          this.$notify({
            message: this.$t('clients.message'),
            type: 'success'
          });
        })
        .catch(err => {
          console.log('Something went wrong', err);
        });
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";
.created_at-column:not(.is-leaf) {
  padding-left: 20px !important;
}

.clients-info {
  .partner-column {
    div {
      max-width: 200px;
    }

  }

  padding: 5rem 0;

  &.locale-usa {
    .clients-info__title {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 4rem;
    }

    .el-table__header,
    .el-table__body {
      font-family: 'Gilroy';
    }

    .el-table__row {
      .el-table__cell:nth-child(-n+2) {
        &.el-table__cell {
          font-weight: 700;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &__title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.6rem;
    margin-bottom: 3rem;
  }

  &__download {
    flex-basis: 5rem;
    // border: 1px solid #d8d9da;
    border: none;
    background: none;
    margin-left: 1rem;
    background-image: url('../assets/icons/icon-xlsx-document.svg');
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;

    .search {
      width: 100%;
    }

    .selector {
      margin-left: 1rem;
    }
  }

  &__table {

    .el-table__row {

      &--deactivated {

        td {
          background-color: rgba($redLight , .1);
        }

        &:hover {

          td {
            background-color: rgba($redLight , .2) !important;
          }
        }
      }
    }

    a {
      color: var(--primary);
    }

    &-button {
      background: var(--primary);
      border: 1px solid var(--primary);
      padding: 1rem 2rem;
      border-radius: 4px;
      color: #fff;
      width: 100%;
    }

    &-date {
      color: #9BA2AB;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    &-id {

      .cell {
        position: relative;
        overflow: visible;
      }

      &__short {
        display: inline-block;
        position: relative;

        span {
          cursor: pointer;
          width: 8rem;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: color .4s;
        }

        &::before {
          cursor: pointer;
          z-index: 20;
          width: 18px;
          height: 18px;
          content: '';
          position: absolute;
          left: 90%;
          bottom: 90%;
          background-image: url(../assets/icons/icon-copy-id.svg);
          background-repeat: no-repeat;
          opacity: 0;
          transition: opacity .4s;
        }

        &.locale-usa {
          &::before {
            background-image: url(../assets/icons/icon-copy-id-usa.svg);
          }
        }

        &:hover {

          span {
            color: var(--primaryHover);
          }

          &::before {
            opacity: 1;
          }
        }
      }
    }

    &-change {
      color: $primary;
      cursor: pointer;
      display: flex;
      align-items: center;

      &>img {
        margin-right: 0.4rem;
      }
    }

    &-edit {

      .cell {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &__btn {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        width: 80%;
        justify-content: center;

        svg {
          display: block;
          height: auto;

          path {
            transition: fill .4s;
          }
        }

        &:hover {

          svg {

            path {
              fill: $primaryHover;
            }
          }
        }
      }
    }

    &-deactivated {
      width: 100%;
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.9rem;
      color: $redLight;
      text-align: center;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .el-pagination .btn-next,
    .el-pagination .btn-prev,
    .el-pager li {
      background: transparent;
    }
  }

  @include below(993px) {
    padding-top: 32px;

    &__title {
      font-size: 24px;
      line-height: 1.4;
      margin-bottom: 24px;
    }

    &__filters {
      margin-bottom: 24px;
    }
  }

  @include below(576px) {
    padding-top: 24px;

    &__title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    &__filters {
      flex-direction: column;
      margin-bottom: 16px;

      .search {
        width: 100%;
        margin-bottom: 8px;
      }

      .selector {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
</style>
