import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AuthLayout from './layouts/AuthLayout'
import BlanketLayout from './layouts/BlanketLayout'
import BaseLayout from './layouts/BaseLayout'
import ManagerLayout from './layouts/ManagerLayout'
import SimpleLayout from './layouts/SimpleLayout';
import ErrorLayout from "./layouts/ErrorLayout";
import MainLayout from "./layouts/MainLayout";
import { Radio, RadioGroup, Tabs, TabPane, Pagination, Dialog, MessageBox, Notification, Table, TableColumn, Loading, Select, Option, Dropdown, DropdownItem, DropdownMenu, Carousel, CarouselItem, Collapse, CollapseItem, Popconfirm, Popover, Checkbox, CheckboxGroup, DatePicker, Tooltip, Button, TimeSelect } from 'element-ui';
import VueKinesis from 'vue-kinesis'
import langRU from 'element-ui/lib/locale/lang/ru-RU'
import langEN from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import './assets/styles/style.scss'
import axios from "axios";
import VueTheMask from 'vue-the-mask'
import VueYandexMetrika from 'vue-yandex-metrika' 
import VueI18n from 'vue-i18n'
import { Languages, numberFormat, dateTimeFormat } from './i18n'
import VueMeta from 'vue-meta'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueClipboard from 'vue-clipboard2'

const localePlugin = require(process.env.VUE_APP_THEME_PLUGIN).default
Vue.use(VueClipboard)
Vue.use(VueI18n)

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

Vue.use(VueReCaptcha,{
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true
  }
})

const messages = Object.assign(Languages)

const i18n = new VueI18n({
  locale: process.env.VUE_APP_API_LANG, // set locale
  messages, // set locale messages
  numberFormat,
  dateTimeFormat
})

Vue.config.productionTip = false
let lang = process.env.VUE_APP_API_LANG==='ru'? langRU : langEN
locale.use(lang)

Vue.use(localePlugin)
Vue.component('AuthLayout', AuthLayout);
Vue.component('BlanketLayout', BlanketLayout);
Vue.component('BaseLayout', BaseLayout);
Vue.component('ManagerLayout', ManagerLayout);
Vue.component('SimpleLayout', SimpleLayout);
Vue.component('ErrorLayout', ErrorLayout);
Vue.component('MainLayout', MainLayout);

Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(TimeSelect);
Vue.use(Popover);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Popconfirm);
Vue.use(DatePicker);
Vue.use(Tooltip);
Vue.use(Button);
Vue.use(VueKinesis)
Vue.use(VueTheMask)
if(process.env.VUE_APP_LOCALE === 'RU') {
  Vue.use(VueYandexMetrika, {
    id: 91039222,
    env: process.env.NODE_ENV,
    options: {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true,
    }
  })
}
Vue.use(Loading.directive);
Vue.prototype.$notify = Notification;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;

Vue.filter('toCurrency', function (value) {
  if (!value || typeof value !== "number") return value
  let formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0
  });
  return formatter.format(value);
})

const UNAUTHORIZED = 401;
const UNAVAILABLE = 503;
axios.interceptors.response.use(
    response => response,
    error => {
      const {status} = error.response;
      if (status === UNAUTHORIZED) {
        router.push({ path: 'login' })
      }
      if (status === UNAVAILABLE) {
        localStorage.removeItem('token')
        localStorage.removeItem('client_token')
        localStorage.removeItem('verified')
        localStorage.removeItem('active_client')
        localStorage.removeItem('activeBA')
        localStorage.removeItem('sortParams')
        router.push({ path: 'technical-works' })
      }
      return Promise.reject(error);
    }
);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
