<template>
    <div 
      class="date-picker"
      :class="{'date-picker--disabled': disabled}"
    >
      <div 
        class="date-picker__title" 
        :class="{'date-picker__title--empty': !datepickerHasDate}"
      >
        {{ title }}
      </div>
      <div class="date-picker__icons-group">
        <img 
          v-if="datepickerHasDate"
          @click="clearDate" 
          src="@/assets/icons/icon-delete-4.svg" 
          alt="Очистить дату"
          class="date-picker__clear-datepicker-icon"
        >
        <img src="@/assets/icons/icon-calendar.svg" alt="date picker">
      </div>
      <div v-if="lang==='en'" :key="lang">
          <date-range-picker
            class="date-picker__calendar"
            ref="picker"
            v-model="singleDate"
            single-date-picker="single"
            :minDate="minDate"
            :auto-apply="true"
            :always-show-calendars="true"
            :ranges="false"
            :disabled="disabled"
            :locale-data="localeEn"
            @update="handleUpdate"
          >
            <template #input="picker">
                {{ fixDate(picker.startDate, picker.endDate) }}
            </template>
        </date-range-picker>
      </div>
      <div v-if="lang==='ru'" :key="lang">
          <date-range-picker
            class="date-picker__calendar"
            ref="picker"
            v-model="singleDate"
            :single-date-picker="isSingleDatePicker ? 'single' : 'range'"
            :append-to-body="appendToBody"
            :minDate="minDate"
            :auto-apply="true"
            :always-show-calendars="true"
            :ranges="false"
            :locale-data="localeRu"
            :disabled="disabled"
            @update="handleUpdate"
          >
            <template #input="picker">
                {{ fixDate(picker.startDate, picker.endDate) }}
            </template>
        </date-range-picker>
      </div>
      
    </div>
  </template>
  
  <script>
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  
  export default {
    name: "DatePicker",
    components: {
      DateRangePicker
    },
    props: {
      isSingleDatePicker: {
        type: Boolean,
        default: true
      },
      appendToBody: {
        type: Boolean,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: ''
      },
      useTimezone: {
        type: Boolean,
        default: false
      },
      value: null,
    },
    data () {
      return {
        lang: this.$i18n._vm.locale,
        date: {
          startDate: new Date(),
  
          endDate: new Date(),
        },
        // singleDate: {
        //     startDate:new Date()
        // },
        minDate: null,
  
        localeEn: {
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
          daysOfWeek: ['sun', 'mon', 'tu', 'wen', 'th', 'fr', 'sat'],
          monthNames: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
          firstDay: 1
        },
        localeRu: {
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
          daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
          monthNames: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'нояб', 'дек'],
          firstDay: 1
        },
        monthsRu: [
          {
            id: 0,
            shortName: 'янв'
          },
          {
            id: 1,
            shortName: 'фев'
          },
          {
            id: 2,
            shortName: 'мар'
          },
          {
            id: 3,
            shortName: 'апр'
          },
          {
            id: 4,
            shortName: 'май'
          },
          {
            id: 5,
            shortName: 'июн'
          },
          {
            id: 6,
            shortName: 'июл'
          },
          {
            id: 7,
            shortName: 'авг'
          },
          {
            id: 8,
            shortName: 'сен'
          },
          {
            id: 9,
            shortName: 'окт'
          },
          {
            id: 10,
            shortName: 'нояб'
          },
          {
            id: 11,
            shortName: 'дек'
          }
        ],
        monthsEn: [
          {
            id: 0,
            shortName: 'jan'
          },
          {
            id: 1,
            shortName: 'feb'
          },
          {
            id: 2,
            shortName: 'mar'
          },
          {
            id: 3,
            shortName: 'apr'
          },
          {
            id: 4,
            shortName: 'may'
          },
          {
            id: 5,
            shortName: 'jun'
          },
          {
            id: 6,
            shortName: 'jul'
          },
          {
            id: 7,
            shortName: 'aug'
          },
          {
            id: 8,
            shortName: 'sep'
          },
          {
            id: 9,
            shortName: 'oct'
          },
          {
            id: 10,
            shortName: 'nov'
          },
          {
            id: 11,
            shortName: 'dec'
          }
        ],
      }
    },
    computed: {
      datepickerHasDate() {
        return this.singleDate.startDate && this.singleDate.endDate
      },
      singleDate: {
        get() {
          if (!this.isSingleDatePicker) {
            return {
              startDate: this.value.startDate,
              endDate: this.value.endDate,
            }
          }
          
          return {
            startDate: this.value !== undefined ? this.value : new Date(),
            endDate: this.value !== undefined ? this.value : new Date(),
          }
        },
        set() {}
      },
      getLang() {
        let language = this.$i18n._vm.locale
        return language
      }
    },
    watch:{
      getLang: {
        deep: true,
        handler(val) {
          this.dataPickerforceRerender(val)
        }
      },
    },
    methods: {
      clearDate(event) {
        if (!this.disabled) {
          event.stopPropagation();
          this.$emit('changeDates', { startDate: null, endDate: null })
        }
      },
      dataPickerforceRerender(value) {
        this.lang = value
      },
      handleUpdate(val) {
        const timezone = this.date.startDate.getTimezoneOffset()
        let changedVal = JSON.parse(JSON.stringify(val))
        changedVal.startDate = new Date(new Date(changedVal.startDate).setHours(((Math.abs(timezone)) / 60)))
        this.$emit('changeDates', this.useTimezone ? changedVal : val)
      },
      fixDate (start, end) {
        if (start && end) {
          let str = ''
          const startDay = start.getDate()
          const endDay = end.getDate()
  
          const startMonth = start.getMonth()
          const endMonth = end.getMonth()
  
          let nameStartMonth, nameEndMonth
  
          if(this.$i18n._vm.locale==='ru') {
            nameStartMonth = this.monthsRu.find(el => el.id === startMonth).shortName
            nameEndMonth = this.monthsRu.find(el => el.id === endMonth).shortName
          } else if (this.$i18n._vm.locale==='en') {
            nameStartMonth = this.monthsEn.find(el => el.id === startMonth).shortName
            nameEndMonth = this.monthsEn.find(el => el.id === endMonth).shortName
          }
  
          if (startDay === endDay && startMonth === endMonth) {
            str = startDay + ' ' + nameStartMonth
          } else if (startMonth === endMonth) {
            str = startDay + '-' + endDay + ' ' + nameStartMonth
          } else {
            str = startDay + ' ' + nameStartMonth + ' - ' + endDay + ' ' + nameEndMonth
          }
  
          return str
        }
      }
    },
    mounted() {
      
      // const endMonth = this.date.endDate.getMonth()
      // const endYear = this.date.endDate.getFullYear()
      // const start = new Date(endYear + '/' + (endMonth + 1) + '/01')
      // if (start) this.date.startDate = start
      // this.handleUpdate(this.date)
    }
  }
  </script>
  
  <style lang="scss" scoped>
    @import "../../assets/styles/helpers/variables";
    @import "../../assets/styles/helpers/mixin";
  
    .date-picker {
      border: 1px solid $gray3;
      border-radius: 2px;
      position: relative;
      padding: 0.7rem 1.5rem;
      height: 50px;
      width: 100%;

      &--disabled .reportrange-text {
        cursor: default;
        pointer-events: none;
      }
  
      &__title {
        transition: all 0.3s ease-out;
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: $textGrey;
        margin-bottom: 0.2rem;

        &--empty {
          position: absolute;
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: $textGrey;
          top: 0;
          height: 100%;
          display: flex;
          align-items: center;
          z-index: 0;
          left: 1.4rem;
        }
      }
  
      &__icons-group {
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        position: absolute;
        right: 1.5rem;
        top: 1.3rem;
      }

      &__clear-datepicker-icon {
        z-index: 1;
      }
  
      &__calendar {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
  
        ::v-deep .reportrange-text {
          background: transparent;
          border: none;
          font-size: 1.6rem;
          line-height: 1.9rem;
          height: 100%;
          padding: 0.7rem 1.5rem;
          margin-top: 1.6rem;
        }
  
        .daterangepicker td.active,
        .daterangepicker td.active:hover {
          background-color: var(--primary);
        }
      }
    }
  </style>
  