<template>
  <div class="date-picker">
    <div v-if="!date" class="date-picker__title">
      {{ title }}
    </div>
    <div class="date-picker__icon">
      <img src="@/assets/icons/icon-calendar.svg" alt="date picker">
    </div>
    <div v-if="lang==='en'" :key="lang">
        <date-range-picker
          class="date-picker__calendar"
          ref="picker"
          v-model="date"
          single-date-picker="range"
          :minDate="minDate"
          :auto-apply="true"
          :always-show-calendars="true"
          :ranges="false"
          :locale-data="localeEn"
          @update="handleUpdate"
        >
          <template #input="picker">
            {{ fixDate(picker.startDate, picker.endDate) }}
          </template>
      </date-range-picker>
    </div>
    <div v-if="lang==='ru'" :key="lang">
        <date-range-picker
          class="date-picker__calendar"
          ref="picker"
          v-model="date"
          single-date-picker="range"
          :minDate="minDate"
          :auto-apply="true"
          :always-show-calendars="true"
          :ranges="false"
          :locale-data="localeRu"
          @update="handleUpdate"
        >
          <template #input="picker">
            {{ fixDate(picker.startDate, picker.endDate) }}
          </template>
      </date-range-picker>
    </div>
    
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: "DatePicker",
  components: {
    DateRangePicker
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    useTimezone: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: String,
      default: null
    },
    startPeriod: {
      type: String,
      default: ""
    },
    endPeriod: {
      type: String,
      default: ""
    },
    width: {
      type: [String, Number],
      default: '22rem'
    },
    height: {
      type: [String, Number],
      default: '5rem'
    }
  },
  data () {
    return {
      lang: this.$i18n._vm.locale,
      date: {
        startDate: new Date(),
        endDate: new Date()
      },
      // minDate: null,

      localeEn: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        daysOfWeek: ['sun', 'mon', 'tu', 'wen', 'th', 'fr', 'sat'],
        monthNames: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        firstDay: 1
      },
      localeRu: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        monthNames: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'нояб', 'дек'],
        firstDay: 1
      },
      monthsRu: [
        {
          id: 0,
          shortName: 'янв'
        },
        {
          id: 1,
          shortName: 'фев'
        },
        {
          id: 2,
          shortName: 'мар'
        },
        {
          id: 3,
          shortName: 'апр'
        },
        {
          id: 4,
          shortName: 'май'
        },
        {
          id: 5,
          shortName: 'июн'
        },
        {
          id: 6,
          shortName: 'июл'
        },
        {
          id: 7,
          shortName: 'авг'
        },
        {
          id: 8,
          shortName: 'сен'
        },
        {
          id: 9,
          shortName: 'окт'
        },
        {
          id: 10,
          shortName: 'нояб'
        },
        {
          id: 11,
          shortName: 'дек'
        }
      ],
      monthsEn: [
        {
          id: 0,
          shortName: 'jan'
        },
        {
          id: 1,
          shortName: 'feb'
        },
        {
          id: 2,
          shortName: 'mar'
        },
        {
          id: 3,
          shortName: 'apr'
        },
        {
          id: 4,
          shortName: 'may'
        },
        {
          id: 5,
          shortName: 'jun'
        },
        {
          id: 6,
          shortName: 'jul'
        },
        {
          id: 7,
          shortName: 'aug'
        },
        {
          id: 8,
          shortName: 'sep'
        },
        {
          id: 9,
          shortName: 'oct'
        },
        {
          id: 10,
          shortName: 'nov'
        },
        {
          id: 11,
          shortName: 'dec'
        }
      ],
    }
  },
  computed: {
    getLang() {
      let language = this.$i18n._vm.locale
      return language
    }
  },
  watch:{
    getLang: {
      deep: true,
      handler(val) {
        this.dataPickerforceRerender(val)
      }
    },
    startPeriod: {
      deep: true,
      handler(val) {
        if(val) {
          this.date.startDate = new Date(val)
        } 
      }
    },
    endPeriod: {
      deep: true,
      handler(val) {
        if(val) {
          this.date.endDate = new Date(val)
        }
      }
    }
    
  },
  methods: {
    updateStartDate() {
      setTimeout(()=> {
        if (this.startPeriod) {
          this.date.startDate = new Date(this.startPeriod)
        }
      }, 500)
    },
    updateEndDate() {
      setTimeout(()=> {
        if (this.endPeriod) {
          this.date.endDate = new Date(this.endPeriod)
        }
      }, 500)
    },
    dataPickerforceRerender(value) {
      this.lang = value
    },
    handleUpdate(val) {
      const timezone = this.date.startDate.getTimezoneOffset()
      let changedVal = JSON.parse(JSON.stringify(val))
      changedVal.startDate = new Date(new Date(changedVal.startDate).setHours(((Math.abs(timezone)) / 60)))
      this.$emit('changeDates', this.useTimezone ? changedVal : val)
    },
    fixDate (start, end) {
      if (start && end) {
        let str = ''
        const startDay = start.getDate()
        const endDay = end.getDate()

        const startMonth = start.getMonth()
        const endMonth = end.getMonth()

        let nameStartMonth, nameEndMonth

        if(this.$i18n._vm.locale==='ru') {
          nameStartMonth = this.monthsRu.find(el => el.id === startMonth).shortName
          nameEndMonth = this.monthsRu.find(el => el.id === endMonth).shortName
        } else if (this.$i18n._vm.locale==='en') {
          nameStartMonth = this.monthsEn.find(el => el.id === startMonth).shortName
          nameEndMonth = this.monthsEn.find(el => el.id === endMonth).shortName
        }

        // const nameStartMonth = this.months.find(el => el.id === startMonth).shortName
        // const nameEndMonth = this.months.find(el => el.id === endMonth).shortName

        if (startDay === endDay && startMonth === endMonth) {
          str = startDay + ' ' + nameStartMonth
        } else if (startMonth === endMonth) {
          str = startDay + ' - ' + endDay + ' ' + nameStartMonth
        } else {
          str = startDay + ' ' + nameStartMonth + ' - ' + endDay + ' ' + nameEndMonth
        }

        return str
      }
    }
  },
  created() {
    this.updateStartDate()
    this.updateEndDate()
  },
  mounted() {
    const endMonth = this.date.endDate.getMonth()
    const endYear = this.date.endDate.getFullYear()
    const start = new Date(endYear + '/' + (endMonth + 1) + '/01')
    if (start) this.date.startDate = start
    this.handleUpdate(this.date)
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/helpers/variables";
  @import "../../assets/styles/helpers/mixin";

  .date-picker {
    // background: $backgroundInput;
    border: 1px solid $border;
    border-radius: 2px;
    height: v-bind(height);
    width: v-bind(width);
    position: relative;
    padding: 0.7rem 1.5rem;

    &__title {
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: $textGrey;
      margin-bottom: 0.2rem;
    }

    &__icon {
      cursor: pointer;
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      z-index: 1;
    }

    &__calendar {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      ::v-deep .reportrange-text {
        background: transparent;
        border: none;
        padding: 1.5rem 1.5rem 0 1.5rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
        height: 100%;
      }

      .daterangepicker td.active,
      .daterangepicker td.active:hover {
        background-color: var(--primary);
      }
    }
  }
</style>
