<template>
  <div class="analytics">
    <AnalyticsHeader @onChangeSubTab="activeSubTab = $event" />
    <div class="analytics__container">
      <AnalyticsOverview 
        v-if="activeSubTab === AnalyticsSubTabsKey.VIEW"
      />
      <AnalyticsDetailed 
        v-if="activeSubTab === AnalyticsSubTabsKey.ACCOUNTS_AND_COMPANIES"
      />
    </div>
    
    <AnalyticsPlaceholder class="analytics__placeholder" />
  </div>
</template>
  
<script>
import AnalyticsHeader from "@/views/Analytics/AnalyticsHeader";
import AnalyticsPlaceholder from "@/views/Analytics/AnalyticsPlaceholder";
import { AnalyticsSubTabsKey } from "@/views/Analytics/AnalyticsHeader";
import AnalyticsDetailed from "@/views/Analytics/AnalyticsDetailed/AnalyticsDetailed";
import AnalyticsOverview from "@/views/Analytics/AnalyticsOverview/AnalyticsOverview";

export default {
  name: 'Analytics',
  components: {
    AnalyticsHeader,
    AnalyticsPlaceholder,
    AnalyticsDetailed,
    AnalyticsOverview,
  },
  data: () => ({
    AnalyticsSubTabsKey,
    activeSubTab: AnalyticsSubTabsKey.VIEW
  })
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
.analytics {
  &__placeholder {
    margin-top: 74px;
  }

  &__container {
    margin-top: 20px;
  }
}
</style>
