
<template>
  <div class="analytics-overview-doughnut-chart">
    <DoughnutChartElement 
      class="analytics-overview-doughnut-chart__chart"
      ref="doughnutChart" 
      :data="dataDoughnutChart" 
    />
    <div class="analytics-overview-doughnut-chart__container">
      <div class="analytics-overview-doughnut-chart__total-block">
        <span class="analytics-overview-doughnut-chart__total-number">{{ totalNumber }}</span>
        <span 
          class="analytics-overview-doughnut-chart__total-percent"
          :style="{ color: totalPercentColor }"
        >{{ totalPercent }}%</span>
      </div>
      <ul class="analytics-overview-doughnut-chart__list">
        <li 
          v-for="(item, index) in dataList" 
          :key="index" 
          class="analytics-overview-doughnut-chart__list-item"
        >
          <span 
            class="analytics-overview-doughnut-chart__point" 
            :style="{ background: item.color }"
          ></span>
          <span class="analytics-overview-doughnut-chart__service">
            {{ item.service }}
          </span>
          <span class="analytics-overview-doughnut-chart__count">{{ item.count }}</span>
          <span 
            class="analytics-overview-doughnut-chart__percent"
            :style="{ color: item.percent > 0 ? '#27AE60' : '#EB5757' }"
          >{{ item.percent }}%</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsOverviewDoughnutChart',
  components: {
    DoughnutChartElement: () => import('@/components/elements/DoughnutChartElement'),
  },
  props: {
    dataDoughnutChart: {
      type: Object,
      required: true
    },
    totalNumber: {
      type: Number,
      required: true
    },
    totalPercent: {
      type: Number,
      required: true
    },
    dataList: {
      type: Array,
      required: true
    }
  },
  computed: {
    totalPercentColor() {
      return this.totalPercent > 0 ? '#27AE60' : '#EB5757';
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.analytics-overview-doughnut-chart {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  &__count, &__percent {
    font-weight: 400;
    font-size: 12px;
  }

  &__count {
    margin-right: 10px;
    color: $gray;
  }

  &__service {
    flex: 1 1 auto;
    font-weight: 400;
    font-size: 14px;
    color: $basicBlack;
  }

  &__point {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 0;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 16px;
    margin-bottom: 6px;
  }

  &__total-percent {
    font-weight: 400;
    font-size: 16px;
  }

  &__total-number {
    font-weight: 700;
    font-size: 20px;
  }

  &__total-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__container {
    max-width: 264px;
    width: 100%;
  }

  &__chart {
    width: 150px;
    height: 150px;
  }
}
</style>
