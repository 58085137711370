export const ServiceType = {
  AVITO: 'AVITO',
  GOOGLE: 'GOOGLE',
  MEDIAPLATFORM: 'MEDIAPLATFORM',
  METRICAPRO: 'METRICAPRO',
  MYTARGET: 'MYTARGET',
  PROMOPAGES: 'PROMOPAGES',
  TELEGRAM: 'TELEGRAM',
  VKADS: 'VKADS',
  VKMARKET: 'VKMARKET',
  VKONTAKTE: 'VKONTAKTE',
  YANDEX: 'YANDEX',
  YANDEXBUSINESS: 'YANDEXBUSINESS',
}

export const ServiceName =  {
  [ServiceType.AVITO]: "Авито",
  [ServiceType.GOOGLE]: "Google Ads",
  [ServiceType.MEDIAPLATFORM]: "Медиаплощадки",
  [ServiceType.METRICAPRO]: "Метрика Про",
  [ServiceType.MYTARGET]: "myTarget",
  [ServiceType.PROMOPAGES]: "Яндекс ПромоСтраница",
  [ServiceType.TELEGRAM]: "Telegram",
  [ServiceType.VKADS]: "VK Реклама",
  [ServiceType.VKMARKET]: "VK Маркет",
  [ServiceType.VKONTAKTE]: "ВКонтакте",
  [ServiceType.YANDEX]: "Яндекс Директ",
  [ServiceType.YANDEXBUSINESS]: "Яндекс Бизнес"
}

