<template>
  <el-tabs
    :type="type"
    class="tabs"
    v-model="internalActiveTab"
    @tab-click="handleTabClick"
  >
    <el-tab-pane
      v-for="tab in tabs"
      :key="tab.name"
      :label="tab.label"
      :name="tab.name"
    ></el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    type: {
      type: String,
      default: '',
      validator: function(value) {
        return ['card', 'border-card', ''].includes(value);
      }
    },
    value: {
      type: String,
      required: true
    },
    tabs: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  computed: {
    internalActiveTab: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    handleTabClick(tab) {
      this.internalActiveTab = tab.name;
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.tabs.el-tabs.el-tabs--card {
  .el-tabs__item {
    border: none;
    padding: 0 26px;
    height: 48px;
    line-height: 48px;
    transition: background-color 0.3s ease, color 0.2s ease;
    font-weight: 700;
    font-size: 14px;
    color: $grey1;

    &.is-active {
      color: $basicBlack;
      box-shadow: 0px 5px 10px 0px rgba($gray10, 0.1);
      background-color: $primaryLight;
      border-radius: 4px;
    }

    &:hover {
      color: $basicBlack;
    }
  }

  .el-tabs__header {
    border: none;
    margin: 0;
  }

  .el-tabs__content {
    display: none;
  }

  .el-tabs__nav {
    border: none;
    & > *:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
}

.tabs.el-tabs {
  .el-tabs__item {
    padding: 0 15px;
    height: 50px;
    line-height: 50px;
    transition: background-color 0.3s ease, color 0.2s ease;
    font-weight: 700;
    font-size: 24px;
    color: $grey1;

    &.is-active {
      color: $basicBlack;
    }

    &:hover {
      color: $basicBlack;
    }
  }

  .el-tabs__active-bar {
    background: $primary;
    border-radius: 5px;
    height: 3px;
  }

  .el-tabs__nav-wrap::after {
    content: none;
  }

  .el-tabs__header {
    border: none;
    margin: 0;
  }
}
</style>