var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-detailed"},[_c('AnalyticsControls',{attrs:{"view":_vm.AnalyticsSubTabsKey.ACCOUNTS_AND_COMPANIES}}),_c('AnalyticsDetailedStatsPanel',{staticClass:"analytics-detailed__stats-panel",attrs:{"data":_vm.analyticsStatsData}}),_c('AnalyticsLineChart',{staticClass:"analytics-detailed__line-chart",attrs:{"title":"Динамика изменения Показов ","subTitle":"Отображение аккаунтов на графике:","lineDataChart":{
      labels: ['01.03.2025', '02.03.2025', '03.03.2025', '04.03.2025'],
      datasets: [
        {
          label: 'VK',
          data: [100, 200, 12, 123],
          borderColor: '#FF974C',
          backgroundColor: 'transparent'
        },
        {
          label: 'Yandex',
          data: [22, 144, 444, 222],
          borderColor: 'red',
          backgroundColor: 'transparent'
        }
      ]
    },"platforms":[
      { name: 'vk', label: 'vk.com/gektor_pro', icon: require('@/assets/icons/icon-vk-graph.svg') },
      { name: 'yandex', label: 'yandex/gektor_pro', icon: require('@/assets/icons/icon-yandex-graph.svg') }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }