<template>
  <div class="analytics-line-chart">
    <h3 class="analytics-line-chart__title">{{ title }}</h3>
    <div class="analytics-line-chart__checkbox-group-container">
      <span>{{ subTitle }}</span>
      <el-checkbox-group 
        class="analytics-line-chart__checkbox-group" 
        v-model="selectedOption"
      >
        <Checkbox 
          v-for="option in options"
          :key="option.value"
          :value="option.value" 
          :label="option.label"
        />
      </el-checkbox-group>
    </div>  
    <LineChartElement
      ref="lineChart"
      :data="lineDataChart"
      :key="chartKey"
      :show-legend="false"
      :tiks-collor="tiksColor"
      :tiks-size="tiksSize"
      :show-grid-lines="showGridLines"
    />
    <div class="analytics-line-chart__platforms-container">
      <div class="analytics-line-chart__platforms-title">Площадки</div>
      <div class="analytics-line-chart__platforms">
        <div 
          v-for="platform in platforms" 
          :key="platform.name" 
          class="analytics-line-chart__platforms-item"
          :class="platform.name"
        >
          <img :src="platform.icon" class="graph-icon">
          {{ platform.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/elements/Checkbox/Checkbox";

export default {
  name: 'AnalyticsLineChart',
  components: {
    Checkbox,
    LineChartElement: () => import('@/components/elements/ReportsLineChartElement'),
  },
  props: {
    title: {
      type: String,
      default: 'График'
    },
    subTitle: {
      type: String,
      default: 'Отображение площадок на графике:'
    },
    options: {
      type: Array,
      default: () => []
    },
    lineDataChart: {
      type: Object,
      default: () => ({
        labels: [],
        datasets: []
      })
    },
    platforms: {
      type: Array,
      default: () => []
    },
    tiksColor: {
      type: String,
      default: '#BFC2C6'
    },
    tiksSize: {
      type: Number,
      default: 14
    },
    showGridLines: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOption: []
    };
  },
  computed: {
    chartKey() {
      return Date.now();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.analytics-line-chart {
  padding: 30px;
  border-radius: 6px;
  background: $basicWhite;

  @include below(768px) {
    padding: 0;
    background: transparent;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    color: $basicBlack;
    margin-bottom: 20px;
  }

  &__checkbox-group-container {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: $gray;
    margin-bottom: 20px;
  }

  &__checkbox-group {
    display: inline;
    margin-left: 20px;
  }

  &__platforms-title {
    color: $basicBlack;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__platforms {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__platforms-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    color: $basicBlack;
  }

  &__platforms-container {
    margin-top: 20px;
  }
}
</style>