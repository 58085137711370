<template>
  <div class="base-layout">
    <Header :isAddBallance="user.role === 'partner'&& isBalanceViewAllowed" v-if="!isReferralOpened" />
    <ReferralHeader v-else/>
    <InfoForManager v-if="(role === 'manager' || role === 'finance' || role === 'admin') && !isReferralOpened" />
    <InfoAboutAgent v-if="(role === 'manager' || role === 'finance' || role === 'admin') && isReferralOpened" />
    <div v-if="activeAccount ||
      $route.name === 'Cashback' ||
      $route.name === 'HelpDesk' ||
      $route.name === 'ProgramDescription' || 
      $route.name ==='ReferralStatistics' || 
      $route.name ==='ReferralHistory'" 
      class="app-pages">
      <div class="app-pages__row">
        <div v-if="activeAccount && showSidebar" class="app-sidebar">
          <Sidebar 
            v-if="isBalanceViewAllowed && !isReferralOpened" 
            :isAddBallance="user.role === 'partner' && disabledDeposit" 
          />
          <ReferralSidebar v-else/>
          <Disclaimer background="#E6EAF2"  v-if="currentPlatform ==='google' && locale ==='RU'" :platform="currentPlatform">
            <template v-if="currentPlatform==='google'" v-slot:link-google>
              <p class="disclaimer__content-text">
                В связи с ограничениями со стороны рекламной площадки Google, 
                есть проблемы с подтверждением деятельности рекламодателя от лица российских компаний. 
                Гарантированно пройти проверку коммерческой деятельности рекламодателя возможно только 
                от лица зарубежного ЮЛ
              </p>
            </template>
          </Disclaimer>
          <Disclaimer background="#E6EAF2"  v-if="(currentPlatform ==='telegram' || currentPlatform ==='google') && locale ==='RU'">
            <template v-slot:content>
              <p class="disclaimer__content-text" v-html="currentPlatform==='telegram'? telegramDiscText : currentPlatform==='google'? googleDiscText: ''"/>
            </template>
            <template v-if="currentPlatform==='google'" v-slot:link-google>
              <p class="disclaimer__content-text">
                <a class="disclaimer__content-link" href="https://support.google.com/adspolicy/answer/9870201?hl=ru" target="_blank">https://support.google.com/adspolicy/answer/9870201?hl=ru</a>
              </p>
            </template>
            <template v-if="currentPlatform==='telegram'" v-slot:link-telegram>
              <p class="disclaimer__content-text">
                <a class="disclaimer__content-link" href="/docs/moderate_telegram_ads.pdf" target="_blank">Скачать информацию о модерации в Telegram ADS</a>
              </p>
            </template>
            <template v-if="currentPlatform==='telegram'" v-slot:footer-content>
              <p class="disclaimer__content-text">
                Комиссия по категориям: <br>
                Крипта – 30% <br>
                Образование – 25% <br>
                Недвижимость - 20%
              </p>
            </template>
          </Disclaimer>
          <Disclaimer background="#E6EAF2"  v-if="currentPlatform ==='metricapro' && locale ==='RU'">
            <template v-slot:content>
              <p class="disclaimer__content-text" v-html="metricaproDiscText"/>
            </template>
          </Disclaimer>
          <Disclaimer background="#E6EAF2"  v-if="isReferralOpened && locale ==='RU' && currentRouteName === 'ReferralHistory'">
            <template v-slot:content>
              <p class="disclaimer__content-text" v-html="referralsOperationsTypesText"/>
            </template>
          </Disclaimer>
          <LinkElement 
            v-if="locale === 'RU' && !isReferralOpened" 
            class="outside-link app-sidebar__link"
          />
          
        </div>
        <div v-if="!activeAccount && isReferralOpened && $route.name !== 'ProgramDescription'" class="app-sidebar">
          <ReferralSidebar/>
          <Disclaimer background="#E6EAF2"  v-if="isReferralOpened && locale ==='RU' && currentRouteName === 'ReferralHistory'">
            <template v-slot:content>
              <p class="disclaimer__content-text" v-html="referralsOperationsTypesText"/>
            </template>
          </Disclaimer>
        </div>
        <div class="app-content">
          <slot />
        </div>
      </div>
     
    </div>
    <div v-else class="app-no-data">
      <div  class="app-add-business-account">
        <div class="app-no-data__title">{{ $t('no_account') }}</div>
        <div class="app-no-data__text">{{ $t('add_new_business_account') }}</div>
        <ButtonElement v-if="data" :text="$t('button_props.add_business_account')" class="app-no-data__button" @click-button="addAccount" />
      </div>
      <div v-if="Object.keys(user).length > 0 && !user.agent && locale === 'RU' && role === 'user'" class="app-became-referral">
        <div class="app-no-data__title">{{ $t('become_referral') }}</div>
        <div class="app-no-data__text">{{ $t('join_referral') }}</div>
        <ButtonElement v-if="data" :text="$t('button_props.join_referral')" class="app-no-data__button" @click-button="addAgent" />
        <div class="app-no-data__notice">
          {{ $t('ref_notice') }}
        </div>
      </div>
    </div>
    <!-- <Support v-if="role === 'user' || role === ''" /> -->
    <Dialog />
   
    <div class="notifications-container">
      <Agreements v-if="role === 'user' || role === ''" />
      <MailNotification />
      <CookiesComponent />
    </div>
    <div class="telegram-widget-wrapper" v-if="locale === 'RU' && isShowTelegramWidget && !isReferralOpened">
        <WidgetLinkElement
            @mouseover.native="showExpandIfNotClosing"
            @dontShow="closeTelegramVidget"
            @close="hideLinkAndSetClosing"
            v-show="showLink" 
            v-if="locale === 'RU' && isTelegramVidgetShow" 
            class="telegram-outside-link app-sidebar__link"
            :isExpand="isExpand"
          />
      </div>
      <PollModal
        v-if="locale==='RU' && isPollModalOpen" 
        :isModalOpen="isPollModalOpen"
        @close-pool-modal="closePollModal"
        @follow-poll="followPoll"
      />
  </div>
</template>

<script>
import Header from "@/components/blocks/Header";
// import Sidebar from "@/components/blocks/Sidebar";
const Sidebar = () => import("@/components/blocks/Sidebar");
import InfoForManager from "@/components/blocks/InfoForManager";
import InfoAboutAgent from "@/components/blocks/InfoAboutAgent";
// import Support from "@/components/elements/Support";
import CookiesComponent from "@/components/blocks/Cookies";
import Agreements from "@/components/blocks/Agreements";
import MailNotification from "@/components/blocks/MailNotification";
import ButtonElement from "@/components/elements/ButtonElement";
import LinkElement from "../components/elements/LinkElement";
import WidgetLinkElement from "../components/elements/WidgetLinkElement";

/* import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css' */
import Disclaimer from "../components/elements/Disclaimer.vue";
import * as Cookies from 'js-cookie'
import PollModal from "../components/elements/PollModal.vue";
import { getRepresentativeAccess } from "@/utils/helpers";
import ReferralHeader from "@/components/blocks/ReferralHeader";
import ReferralSidebar from "@/components/blocks/ReferralSidebar";



export default {
  name: 'Base',
  components: {
    Header,
    Sidebar,
    // Support,
    InfoForManager,
    InfoAboutAgent,
    CookiesComponent,
    Agreements,
    MailNotification,
    ButtonElement,
    /* VueSlickCarousel, */
    LinkElement,
    Disclaimer,
    Dialog: () => import('../components/blocks/Dialog'),
    WidgetLinkElement,
    PollModal,
    ReferralHeader,
    ReferralSidebar
},
  data() {
    return {
      showDeposit: false,
      locale: process.env.VUE_APP_LOCALE,
      telegramDiscText: 'Перед открытием рекламного кабинета необходимо заранее убедиться, что тематика не противоречит правилам площадки, так как пройти модерацию трудно.',
      googleDiscText: 'Для открытия рекламного кабинета Google Ads остаток <b>на балансе </b> должен быть <b>не менее 10 000 руб.</b> <br><br>Если рекламный кабинет попал в <b>БЛОК</b> по причине нарушения правил площадки Google Ads – <b>ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ</b> с рекламного аккаунта – <b>НЕВОЗМОЖЕН</b>. <br><br> Если <b>2</b> и более ваших рекламных кабинетов попало в <b>БЛОК, открыть</b> новый кабинет <b>НЕЛЬЗЯ.</b> <br><br>В виду последних обновлений политики <b>Google, сразу после создания рекламного кабинета, Google может запрашивать верификацию рекламодателя при создании аккаунта.</b> Эта практика считается нормой. Более подробно о верификации можно ознакомится по ссылке:',
      metricaproDiscText:'<b>Яндекс Метрика Про</b>   — это расширенная версия Яндекс.Метрики, предназначенная для профессионального использования. <br><br> <b>Дополнительные возможности Метрики Про:</b> <br><br> <ul style="padding-left: 15px"><li>Интеграция с Yandex Cloud</li><li>Значительно расширенные лимиты на количество полей, запросов по API, на количество целей и сегментов</li><li>Семплирование: возможность задать уровень выборки на уровне 100%</li><li>Гибкое управление доступами к счетчикам</li><li>API отчетов медийной рекламы</li><li>Расширенная безопасность</li></ul>',
      startDate: "2023-10-16",
      endDate:"2023-11-26",
      showLink: true,
      isExpand: false,
      isTelegramVidgetShow:false,
      isClosing: false,
      isPollModalOpen: false,
      referralsOperationsTypesText:'<ul style="list-style: none; display: flex; flex-direction: column; gap: 2.5rem"><li><b>Начисление</b>  – операция, в результате которой на счет пользователя зачисляются агентские баллы.</li><li><b>Вывод средств</b>  – операция, в результате которой со счета пользователя списываются агентские баллы и обмениваются на денежные средства</li><li><b>Корректировка</b>  – операция, в результате которой изменяется количество агентских баллов, зачисленных или списанных ранее.</li></ul>'
    }
  },
  computed: {
    currentPlatform() {
      const query = { ...this.$route.query }
      if (query.platform) {
          return query.platform 
        }
      return ''
    },

    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    role() {
      return this.$store.state.Auth.role
    },
    data() {
      return this.$store.state.Auth.data
    },
    isShowTelegramWidget() {
      return !(this.$route.name === 'Clients' || this.$route.name === 'Acts' || this.$route.name === 'Contracts' || this.$route.name === 'Creatives')
    },
    showSidebar() {
      let isShow = true
      if (this.$route.name === 'Clients' || 
      this.$route.name === 'Acts' || 
      this.$route.name === 'Contracts' || 
      this.$route.name === 'Creatives' || 
      this.$route.name === 'BusinessAccounts' || 
      this.$route.name === 'Documents' || 
      this.$route.name === 'Reports' || 
      this.$route.name === 'Support' || 
      this.$route.name === 'SupportDetail' || 
      this.$route.name === 'Representatives' || 
      this.$route.matched.some(route => route.name === 'HelpDesk' || this.$route.name === 'ProgramDescription')) isShow = false
      return isShow
    },
    telegramWidgetStatus() {
      let cookieValue = JSON.parse(Cookies.get('isTelegramVidgetShow'));
      return cookieValue === undefined ? true : cookieValue;
    },
    pollModalStatus() {
      let cookieValue = JSON.parse(Cookies.get('isPollModalOpen'));
      return cookieValue === undefined ? true : cookieValue;
    },
    user() {
      return this.$store.state.Auth.user
    },
    isReferralOpened() {
      const open = localStorage.getItem('isOpenReferral')
      return open === 'true' ? true : false
    },
    currentRouteName() {
      return this.$route.name;
    },
    businessAccountInfo() {
      return this.$store.state.Representatives.representativeBAInfo
    },
    permissions() {
      return this.$store.state.Representatives.permissions
    },
    disabledDeposit() {
      if (this.getRepresentativeAccess('ba.invoicing')) {
        return false
      }

      return this.getRepresentativeAccess('ba.balance.view')
    },
    isBalanceViewAllowed() {
      if (this.user.role === 'partner' && this.getRepresentativeAccess('ba.invoicing')) {
        return true
      }

      if(this.user.role === 'partner' && (this.getRepresentativeAccess('ba.balance.view') )) {
        return true
      } else if(this.user.role === 'partner' && !this.getRepresentativeAccess('ba.balance.view')) {
        return false
      }
      return true
    },
  },
  created() {
    // Cookies.remove('isTelegramVidgetShow')
    // this.$store.dispatch('Auth/getBusinessAccounts', this.activeAccount.id)
    if (this.activeAccount ) {
      this.getPlatforms({business_account_id:this.activeAccount.id})
    }
    this.checkisTelegramVidgetShow()
    // this.checkIsPollModalShow()
  },
  mounted() {
    setTimeout(()=> {
      this.isExpand = true
    }, 40000)
    setTimeout(()=> {
      if (this.locale === 'RU') {
        this.openPollModal()
      }
    }, 300000 ) // return 300000
  },
  watch: {
    async activeAccount(val) {
      if (val) {
        this.getPlatforms({business_account_id:this.activeAccount.id})
        
        // Получаем пользователя и сетаем его поле agent в текущего пользователя, чтобы не сломать предыдущую логику
        if (this.activeAccount.user_id && !this.user.agent && this.user.role !== 'user') {
            const data = {
                id: this.activeAccount.user_id,
                token: localStorage.getItem('token')
            }
            
            const user = await this.$store.dispatch('Users/getUser', data)
            this.$store.commit('Auth/setData', { label: 'user', data: {
              ...this.user,
              agent: user.agent
            }})
        }
      }
    },
    businessAccountInfo : {
      deep: true,
      handler(val) {
        if(val && this.role === 'partner') {
          // if(val && this.role === 'partner') {
          //   console.log("getRepresentativeAccess('ba.balance.view')", this.getRepresentativeAccess('ba.balance.view'))
          // }
        }   
      },
    }
  },
  methods: {
    getPlatforms(params) {
      this.$store.dispatch('Ad/getPlatforms', params)
        .then(({ data }) => {
          if (data.success) {
            let arr = []

            let platforms = [...data.data]
            const googleIndex = platforms.findIndex(el => el.slug === 'google')
            if (googleIndex !== -1) {
              const google = platforms.splice(googleIndex, 1)
              platforms = [...platforms, ...google]
            }
            if (this.activeAccount && this.role !=='partner') {
              platforms.forEach((el) => {
                if (this.activeAccount.visible_services[el.slug]) arr.push(el)
              })
            } else {
              arr = platforms
            }
            const query = { ...this.$route.query }
            const params = {
              service: query.platform ? query.platform : arr[0].slug,
              business_account_id: this.activeAccount.id,
              page: 1
            }
            this.$store.commit('Ad/setData', { label: 'accountsList', data: null })
            this.$store.dispatch('Ad/getPlatformBalance', params)
            if(this.role !=='partner') {
              this.$store.dispatch('Ad/getAccounts', params)
            } else {
              const params = {
                service: query.platform ? query.platform : arr[0].slug,
                ba_id: this.activeAccount.id,
                user_id:this.user.id,
                page: 1
              }
              this.$store.dispatch('Ad/getAddAccountsForRepresentative', params)
            }
            
          }
        })
    },
    addAccount() {
      if(this.role === 'manager' && this.role ==='finance') {
        this.$store.commit('Dialog/setData', { label: 'isEditable', data: true })
      } else {
        this.$store.commit('Dialog/setData', { label: 'isEditable', data: false })
      }
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'BusinessAccountData' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    checkDateInRange(dateString, startDateString, endDateString) {
      const date = new Date(dateString);
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);
      return date >= startDate && date <= endDate;
    },
    showExpand() {
      this.isExpand = true
    },
    closeTelegramVidget() {
      // let date = new Date();
      // date.setMinutes(date.getMinutes() + 10);
      Cookies.set('isTelegramVidgetShow', false, {expires: 30})
      this.showLink = false
    },
    checkisTelegramVidgetShow() {
      if(Cookies.get('isTelegramVidgetShow') === undefined){
        this.isTelegramVidgetShow = true
      } else if(!Cookies.get('isTelegramVidgetShow')){
        this.isTelegramVidgetShow = false
      }
    },
    checkIsPollModalShow() {
      if(Cookies.get('isPollModalOpen') === undefined){
        this.isPollModalOpen = true
      } else if(!Cookies.get('isPollModalOpen')){
        this.isPollModalOpen = false
      }
    },
    showExpandIfNotClosing() {
      if (!this.isClosing) {
      this.isExpand = true
      } 
    },
    hideLinkAndSetClosing() {
      this.isClosing = true
      this.isExpand = false
      setTimeout(() => {
        this.isClosing = false
      }, 500)
    },
    closePollModal() {
      this.isPollModalOpen = false
      // let date = new Date();
      // date.setTime(date.getTime() + 180000);
      // Cookies.set("isPollModalOpen", false, { expires: date });
      Cookies.set('isPollModalOpen', false, {expires: 10})
    },
    openPollModal() {
      if(Cookies.get('isPollModalOpen') === undefined ?? Cookies.get('isPollModalOpen') === 'true'){ 
        this.isPollModalOpen = true
      }
    },
    followPoll(){
      this.isPollModalOpen = false
      Cookies.set('isPollModalOpen', false)
    },
    getRepresentativeAccess(permissionToCheck){
      return getRepresentativeAccess(permissionToCheck, this.businessAccountInfo, this.role)
    },
    addAgent() {
      this.$store.commit('Dialog/setData', { label: 'isEditable', data: false })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'ReferralData' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
  }, 
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.base-layout {
  background-color: var(--gray);
  min-height: 100vh;
  height: 100%;
}

.app-pages-base {
  display: flex;
  flex-direction: column;
}

.telegram-widget-wrapper {
  position: sticky;
  bottom: 0;
  max-width: 440px;
  left: 40px;
  /* margin: 0 auto; */
  // padding-left: 3rem;
  // margin-bottom: -6rem;
  z-index: 998;
}

.app-no-data__button {
  margin: 0 auto;
}
@include below(769px) {
  .app-no-data {
    flex-direction: column;
  }
}
</style>
