<template>
  <el-checkbox
    :key="option.value"
    :label="option.value"
    size="large"
    class="checkbox-option"
    :checked="checked"
    @change="handleChange"
  >
    {{ option.label }}
  </el-checkbox>
</template>

<script>
export default {
  name: 'CheckboxOption',
  props: {
    option: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange(isChecked) {
      this.$emit('change', isChecked)
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
.checkbox-option {
  .el-checkbox {
    &__inner {
      border-radius: 50%;
      border-color: $gray3;

      &::after {
        height: 5px;
        left: 4px;
        top: 2px;
      }
    }
    &__input.is-checked .el-checkbox__inner {
      background-color: $primary;
      border-color: $basicWhite;
    }

    &__input.is-checked + .el-checkbox__label {
      color: $basicWhite;
    }
  }
}
</style>